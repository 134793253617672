import { CSSProperties, FC } from 'react';

import { ModalContainer } from '../../onboardingApp/views/components/modalContainer';
import { useLevelUp } from '../../studentApp/hooks';
import { useLayout } from '../../studentApp/hooks/useLayout';

import { LandscapeLayout } from './landscapeLayout';
import { MobileLayout } from './mobileLayout';
import { Modals } from './modals/modals';
import { PortraitLayout } from './portraitLayout';
import { DivRefProvider } from './provider';

const style: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};

export const StudentApp: FC = () => {
  const { isMobile, isPortrait, isLandscape } = useLayout();

  useLevelUp();

  return (
    <DivRefProvider>
      <div className="no-select" style={style}>
        {isMobile && <MobileLayout />}
        {isPortrait && <PortraitLayout />}
        {isLandscape && <LandscapeLayout />}
        <Modals />
        {/* This is a component from the onboardingApp,
            which from legacy contains ui for square animation modal */}
        <ModalContainer />
      </div>
    </DivRefProvider>
  );
};
