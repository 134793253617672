import axios, { AxiosInstance } from 'axios';

export const callSubmit = async (url: string, submitBundle: any) => {
  const axiosInstance = getInstance();
  const { data } = await axiosInstance.post(url, toBody(submitBundle));

  return data;
};

const getInstance = (): AxiosInstance => {
  const ret = axios.create();

  ret.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded';

  return ret;
};

const toBody = (args: any): string => {
  return new URLSearchParams(args as Record<string, string>).toString();
};
