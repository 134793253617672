import axios from 'axios';

import {
  getApiUrl,
  getGuid,
  getSs,
  getUsername,
} from '../../stores/globalState';
import { userState } from '../../stores/userStore';
import { getHistory } from '../../utils';
import { getTimeStampWithMillis } from '../../utils/getTimestamp';
import { jsNames } from '../login';
import { consoleAxiosError, isAxiosError, toBody } from '../utils';

import {
  alreadyBlockedAFrame,
  getBrowserName,
  isKnownIssue,
  isPossibleLoop,
} from './requesHandler';

export async function sendErrorToServer(
  msg: string,
  terminal?: boolean
): Promise<void> {
  try {
    if (terminal) msg = `[Terminal] ${msg}`;

    if (isPossibleLoop(msg)) return;

    if (isKnownIssue(msg)) return;

    if (alreadyBlockedAFrame(msg)) return;

    if (!getUsername()) return;

    let userName = userState().userName || '';

    if (userState().isGhost) userName = '!!! GHOST !!! ' + userName;

    msg = `${getTimeStampWithMillis()}
            ${userName}
            browser: ${getBrowserName()}
            teacher: ${userState().teacherName}
            class: ${userState().className}
            org: ${userState().orgName}
            js: ${jsNames}\n
            sendClientErrorMessage: ${msg}`;

    msg = `${msg} 
            HISTORY:  
            ${getHistory()}`;

    const data = {
      m: msg,
      guid: getGuid(),
      ss: getSs(),
      a: new Date().getTime(),
    };

    const url = `${getApiUrl()}/ErrorToServer`;

    return await axios.post(url, toBody(data));
  } catch (err) {
    return isAxiosError(err) ? consoleAxiosError(err) : console.error({ err });
  }
}
