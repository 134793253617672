import { isBoolean } from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';

import { LoginSuccessResponse, LoginRequest, sendErrorToServer } from '..';
import { NEW_EXAMS_AVAILABLE_MESSAGE } from '../../../cnusr/components/modals/newExamDialog';
import {
  resetActivityMonitor,
  updateTimeStuckDisplay,
} from '../../legacy/activityMonitor';
import { initializePingPolice } from '../../legacy/pingPolice';
import { bannerState, problemState } from '../../stores';
import { setLastMsgId, setTimeStuck } from '../../stores/globalState';
import {
  WELCOME,
  studentAppModalState,
} from '../../stores/studentAppModalStore';
import { userState } from '../../stores/userStore';
import { newWork } from '../../stores/workStore';
import { DEFAULT_ORDER_BY, ID, ProblemObject, ThemeOption } from '../../types';
import { gmmAlert } from '../../utils';
import { harvestTimeSeen, isMobile, logHistory } from '../../utils/gmmUtils';
import { alerts } from '../alerts';
import {
  normalizeExamData,
  notifyNewExam,
  processAvailableWork,
  updateAllTime,
  updateGameCredits,
  updatePermitReadAloud,
  updateReplacementsPerDay,
  updateSwitchToSpiralReviewSettings,
} from '../responseHandlerShared';

interface LoginData {
  packet: LoginRequest;
  data: LoginSuccessResponse;
}

export const processLogin = async ({
  packet,
  data,
}: LoginData): Promise<void> => {
  unstable_batchedUpdates(() => {
    try {
      studentAppModalState().setLoading(true, 'loginReact');

      const internal = packet.loginInternal;

      logHistory(internal ? 'loginInternal' : 'login');

      resetActivityMonitor();

      setTimeStuck(data.timeStuck);
      updateTimeStuckDisplay();

      // initialize timeSeenStart
      harvestTimeSeen();

      // Temporary patch for old WF strategy of sending a selectedRestoreId
      // and a one-item dollarRestoreIds even if there are no problems.
      if (!data.problems) {
        data.selectedRestoreId = undefined;
        data.dollarRestoreIds = [];
        data.problems = {} as Record<ID, ProblemObject>;
        problemState().setNoProblemsOnLogin(true);
      }

      userState().updateUserValues({
        isGhost: !!data.isGhost,
        studentName: data.rN,
        orgName: data.orgName!,
        className: data.clasName!,
        teacherName: data.teacherName!,
        sicId: data.sicId!,
        redisSessionId: data.ss,
        neverCrown: data.neverCrown!,
        userId: data.userId,
      });

      if (data.guid) userState().updateUserValues({ guid: data.guid });

      bannerState().setAllowHand(data.aH);
      updatePermitReadAloud(data.permitReadAloud);
      bannerState().setTeacherOnline(data.tO === true);
      updateSwitchToSpiralReviewSettings(data.switchToSpiralReviewSettings!);
      bannerState().setBlockGames(!!data.bg);
      bannerState().setBlockGamesClass(!!data.bgclas);
      updateReplacementsPerDay(data.replacementsPerDay);
      bannerState().setReplacementsUsedToday(data.replacementsUsedToday);
      bannerState().setAllowReplacementsWhenTeacherOnline(
        data.allowReplacementsWhenTeacherOnline
      );

      if (isBoolean(data.allowWorkOnAnyProblems)) {
        problemState().setDollarsOnly(!data.allowWorkOnAnyProblems);
      }

      if (data.allTimeEligibleOnly) {
        updateAllTime(data.allTimeEligibleOnly);
      }

      setLastMsgId(data.lastMsgId);

      bannerState().setClassHighestToday(data.clasHighestToday);
      bannerState().setPointsThisWeek(data.sw);
      bannerState().setPointsToday(data.s);

      if (data.hw || data.hw === 0) {
        bannerState().setDailyGoal(data.hw);
      }

      updateGameCredits(
        data.gameCredits,
        data.pointsTowardGameCredit,
        data.pointsForGameCredit
      );

      bannerState().setTeacherName(data.teacherName);
      bannerState().setClassName(data.clasName);
      if (data.oC) bannerState().setOtherClasses(data.oC);

      bannerState().setThemeOption(data.theme as ThemeOption);
      problemState().setOrderBy(data.orderBy || DEFAULT_ORDER_BY, false);
      bannerState().setEffects(!!data.effects);
      bannerState().setShowTopScores(!!data.showTopScores);
      bannerState().setShowClock(data.showClock);
      bannerState().setUseNativeKeyboard(!!data.nativeKeyboard);
      bannerState().setTouchKeyboard(!!data.forceTouchKeyboard);
      bannerState().setAllowExamsOnMobile(data.allowExamsOnMobile);

      if (data.games) studentAppModalState().setAvailableGames(data.games);

      if (data.examMessages) {
        studentAppModalState().setAvailableExams(
          normalizeExamData(data.examMessages)
        );
      }

      bannerState().setLoginCount(data.loginCount);

      newWork(data);

      processAvailableWork(data.availableWork);

      initializePingPolice();

      if (!internal) {
        if (!maybeNotifyNewExams(data) && bannerState().loginCount < 5) {
          studentAppModalState().setCurrentModal(WELCOME);
        }
      }
    } catch (err: unknown) {
      gmmAlert(alerts.loginFailed);

      const errorObject =
        err instanceof Error
          ? { message: err.message, name: err.name }
          : { message: 'no error message', name: 'no error name' };

      sendErrorToServer(
        `login logic failed, err: ${JSON.stringify(errorObject, null, 2)}`,
        true
      );
    } finally {
      studentAppModalState().setLoading(false, 'loginReact');
    }
  });
};

function maybeNotifyNewExams(loginData: LoginSuccessResponse): boolean {
  if (isMobile() && !bannerState().allowExamsOnMobile) return false;

  const availableExams = loginData.availableExams;

  if (!availableExams?.length) return false;

  if (availableExams.length === 1) {
    notifyNewExam(availableExams[0].sitId);

    return true;
  }

  studentAppModalState().setNewExamDialog({
    message: NEW_EXAMS_AVAILABLE_MESSAGE,
  });

  return true;
}
