import axios from 'axios';
import { isBoolean } from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';

import { bannerState } from '../../stores';
import {
  getApiUrl,
  getIsTest,
  getLastMsgId,
  setLastMsgId,
} from '../../stores/globalState';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { userState } from '../../stores/userStore';
import { newWork, workState } from '../../stores/workStore';
import { packageGenericError, reboot } from '../../utils';
import { LoginRequest } from '../login';
import {
  APIObserver,
  apiQueue,
  MESSAGE_CHECK_QUEUE_EVICTION_TIMEOUT_MS,
} from '../queue';
import {
  notifyNewExam,
  processAvailableWork,
  updatePermitReadAloud,
  updateSwitchToSpiralReviewSettings,
} from '../responseHandlerShared';
import { sendErrorToServer } from '../sendError';
import {
  handleSessionClosed,
  isAxiosError,
  toBody,
  getAxiosErrorMessage,
} from '../utils';

import {
  snackbarNotifications,
  clasMessages,
  unlockExamProblems,
  updateGameCreditsAndTopScores,
  updateWallet,
  updateAllowReplacementsWhenTeacherOnline,
  updateReplacements,
  examMessages,
} from './responseHandler';
import { teacherToStudentMessages } from './teacherToStudentMessages';
import { PingSuccess } from './types';

interface MessageCheckParams extends LoginRequest {
  getTopScores?: boolean;
  tg?: number;
}

interface PingOptions {
  onPayloadProcessed: () => void;
  onError: () => void;
}

export async function messageCheck(
  getTopScores = false,
  { onPayloadProcessed, onError }: PingOptions
): Promise<void> {
  const apiCall = (apiObserver: APIObserver) => {
    messageCheckHelper(apiObserver, getTopScores, {
      onPayloadProcessed,
      onError,
    });
  };

  apiQueue.add({
    apiCall,
    description: 'MessageCheck',
    evictionTimeout: MESSAGE_CHECK_QUEUE_EVICTION_TIMEOUT_MS,
  });
}

async function messageCheckHelper(
  apiObserver: APIObserver,
  getTopScores = false,
  { onPayloadProcessed, onError }: PingOptions
): Promise<void> {
  try {
    const params: MessageCheckParams = {
      ss: userState().redisSessionId,
      lastMsgId: getLastMsgId(),
      guid: userState().guid,
      user: userState().userName,
    };

    if (getTopScores) params.getTopScores = true;

    if (getIsTest()) params.tg = workState().currentWork.sitId;

    const url = `${getApiUrl()}/MessageCheck`;

    // Postponing retry until WF data is retry-safe
    // const axiosInstance = getAxiosRetryInstance({
    //   retries: 1,
    //   retryDelay: () => 200,
    //   retryCondition: _error => true,
    //   onRetry: (retryCount, _error, _requestConfig) => {
    //     logHistory(`messageCheck retry ${retryCount}`);
    //   },
    // });

    // const { data } = await axiosInstance.post(url, toBody(params));

    const { data } = await axios.post(url, toBody(params));

    const pingSuccess = data as PingSuccess;

    unstable_batchedUpdates(() => {
      if (pingSuccess.sessionClosed) return handleSessionClosed();

      if (pingSuccess.guidFail) return reboot(pingSuccess.guidFail);

      setLastMsgId(pingSuccess.lastMsgId);

      if (!getIsTest()) {
        updateGameCreditsAndTopScores(pingSuccess);
        newWork(pingSuccess.st);
      }

      if (isBoolean(pingSuccess.aH)) {
        // don't let a student's raised hand persist
        if (!pingSuccess.aH) bannerState().setIsHandRaised(false);
        bannerState().setAllowHand(pingSuccess.aH);
      }

      if (isBoolean(pingSuccess.bgclas)) {
        bannerState().setBlockGamesClass(data.bgclas);
      }

      if (pingSuccess.sendMoney) updateWallet(pingSuccess.sendMoney);

      bannerState().setTeacherOnline(pingSuccess.tO);

      if (pingSuccess.hw || pingSuccess.hw === 0) {
        bannerState().setDailyGoal(pingSuccess.hw);
      }

      if (pingSuccess.games)
        studentAppModalState().setAvailableGames(pingSuccess.games);

      if (pingSuccess.clasMsgs) clasMessages(pingSuccess);

      if (isBoolean(pingSuccess.autoUnlockExamsProblems) && getIsTest())
        unlockExamProblems();

      if (pingSuccess.switchToSpiralReviewSettings) {
        updateSwitchToSpiralReviewSettings(
          pingSuccess.switchToSpiralReviewSettings
        );
      }

      if (isBoolean(pingSuccess.allowReplacementsWhenTeacherOnline)) {
        updateAllowReplacementsWhenTeacherOnline(
          pingSuccess.allowReplacementsWhenTeacherOnline
        );
      }

      processAvailableWork(pingSuccess.availableWork);

      if (pingSuccess.notifications) snackbarNotifications(pingSuccess);

      if (pingSuccess.notifyNewExam)
        notifyNewExam(pingSuccess.notifyNewExam.sitId);

      if (pingSuccess.examMessages) examMessages(pingSuccess);

      if (pingSuccess.m) teacherToStudentMessages(pingSuccess);

      updateReplacements(pingSuccess.replacementsPerDay);
      updatePermitReadAloud(pingSuccess.permitReadAloud);
    });

    onPayloadProcessed();
  } catch (err) {
    // manage ping state
    onError();

    if (isAxiosError(err)) {
      sendErrorToServer(getAxiosErrorMessage('Ping', err));

      return;
    }

    // Prior to Fall, '24, we quietly ignored ping logic/processing errors
    sendErrorToServer(`Ping logic fail, error: ${packageGenericError(err)}`);
  } finally {
    apiObserver.done();
  }
}
