import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface UserValues {
  // used for preview of assignment from Mobius
  isGhost: boolean;
  className: string;
  guid: string;
  orgName: string;
  sicId: number;
  redisSessionId: number;
  teacherName: string;
  userId: number;
  userName: string;
  password: string;
  studentName: string;
  neverCrown: boolean;
}

interface UserStore extends UserValues {
  updateUserValues: (values: Partial<UserValues>) => void;
}

export const userStore = create<UserStore>()(
  devtools(
    immer(set => ({
      isGhost: false,
      className: '',
      guid: '',
      orgName: '',
      sicId: 0,
      redisSessionId: 0,
      teacherName: '',
      userId: 0,
      userName: '',
      studentName: '',
      password: '',
      neverCrown: false,
      updateUserValues: (values: Partial<UserValues>) => {
        set(draft => {
          Object.assign(draft, values);
        });
      },
    })),
    { name: 'User Store' }
  )
);

export const userState = (): UserStore => userStore.getState();
