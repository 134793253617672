import { detect } from 'detect-browser';

// ToDo: these are always zero -- so is all this logic meaningless?
const lastError = 0;
const errorCount = 0;
let blockedAFrame = false;

const knownErrorMessageList = [
  "(evaluating 'elt.parentNode')",
  'Session closed',
  'Your teacher connected',
  'due to inactivity',
  'Firefox',
  'Ping fail 0',
] as const;

export const isKnownIssue = (msg: string): boolean => {
  const isAlreadyKnown = knownErrorMessageList.some(message =>
    msg.includes(message)
  );
  const isDuplicate = msg.indexOf(':1') === 0;

  const isKnown = isAlreadyKnown || isDuplicate;

  return !!isKnown;
};

export const isPossibleLoop = (msg: string): boolean => {
  const now = new Date().getTime();
  const reloader = msg?.indexOf('reload...') > -1;
  const isPossibleLoop =
    !reloader && errorCount > 5 && lastError && now - lastError < 10000;

  return !!isPossibleLoop;
};

export const alreadyBlockedAFrame = (msg: string): boolean => {
  if (msg.includes('Blocked a frame') && !blockedAFrame) {
    blockedAFrame = true;

    return true;
  }

  return false;
};

const isStandardError = (error: any): error is Error =>
  error && typeof error === 'object' && 'name' in error;

export const parseError = (error: unknown): Error => {
  if (!isStandardError(error))
    return new Error('Unable to translate error provided');

  return {
    name: error.name,
    message: error.message,
    stack: error.stack || 'missing',
  };
};

const browserInfo = detect(navigator.userAgent);

const checkBrowserName = (name: string): boolean => browserInfo?.name === name;
const checkBrowserOS = (os: string): boolean =>
  browserInfo ? !!browserInfo.os?.match(os) : false;

export const isAndroid = checkBrowserName('android');
export const isChrome =
  checkBrowserName('chrome') || checkBrowserName('chrios');
export const isFirefox = checkBrowserName('firefox');
export const isIOS = checkBrowserOS('iOS');
export const isCrios = checkBrowserOS('Crios');
export const isMac = checkBrowserOS('Mac OS');
export const isWindows = checkBrowserOS('Windows');

export const isSafari = checkBrowserName('safari');

export const isIE = checkBrowserName('ie');
export const isEdge = checkBrowserName('edge');

export const isOldIOS = isIOS && parseInt(browserInfo?.version ?? '', 10) < 12;

export const getBrowserName = (): string => {
  let android = '';

  if (isAndroid) {
    android = ' (android)';
  }

  let ios = '';

  if (isIOS) {
    if (['iPad'].indexOf(navigator.userAgent) >= 0) ios = ' (iPad)';
    else if (['iPhone'].indexOf(navigator.userAgent) >= 0) ios = ' (iPhone)';
    else if (['iPod'].indexOf(navigator.userAgent) >= 0) ios = ' (iPod)';
  }

  let ret = 'other';

  if (isChrome) ret = 'Chrome';
  else if (isSafari) ret = 'Safari';
  else if (isFirefox) ret = 'Firefox';
  else if (isCrios) ret = 'Crios';

  return ret + android + ios;
};
