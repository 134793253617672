import { ArrowDropDown } from '@material-ui/icons';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { FC, useState, MouseEvent, CSSProperties } from 'react';

import { problemStore } from '../../../studentApp/stores';
import { OrderOption } from '../../../studentApp/types';
import { orderOptionsArray } from '../../../studentApp/utils';

const sortByStyle: CSSProperties = {
  display: 'flex',
  marginLeft: 'auto',
  fontSize: '12px',
  fontWeight: 500,
};

export const OrderBy: FC = () => {
  const [orderBy, setOrderBy] = problemStore(state => [
    state.orderBy,
    state.setOrderBy,
  ]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (orderBy: OrderOption) => () => {
    setOrderBy(orderBy);
    handleClose();
  };

  return (
    <div style={sortByStyle}>
      <Tooltip title="Change order of squares">
        <div
          onClick={handleClick}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span style={{ fontWeight: 500 }}>Sort by</span>

          <span
            style={{
              fontWeight: 800,
              marginLeft: '6px',
            }}
          >
            {orderBy}
          </span>

          <ArrowDropDown />
        </div>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {orderOptionsArray.map(orderOption => (
          <MenuItem
            key={orderOption}
            onClick={handleMenuItemClick(orderOption)}
            disabled={orderOption === orderBy}
          >
            {orderOption}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
