import { FC, useEffect, useRef } from 'react';

import { ProblemCanvasOwner, ProblemData, ProblemWidth } from '..';
import { problemModalStore } from '../modals';
import { NativeInputBox } from '../modals/nativeInputBox';
import { ProblemModals } from '../modals/problemModals';
import { useProcessedProblemModalStore } from '../modals/useProblemModalStore';

import { NARROW_PROBLEM_WIDTH } from './problemCanvasConstants';

const MAX_PIXELS_AWAY_FROM_START = 5;

interface ProblemCanvasProps {
  // If you haven't already connected this to a ProblemContext,
  // we will quietly create and connect a default context.
  // This happens inside ProblemCanvasOwner.buildProblemCanvasOwner
  problemCanvasOwner: ProblemCanvasOwner;
  globalFocus?: boolean;
  problemData?: ProblemData;
  problemWidth?: ProblemWidth;
  animates?: boolean;
  userId?: number;
}

export const ProblemCanvas: FC<ProblemCanvasProps> = ({
  problemCanvasOwner,
  globalFocus = true,
  problemData,
  problemWidth,
  animates,
  userId,
}) => {
  const { isShowingAnyModal } = useProcessedProblemModalStore();
  const [nativeInputLocation] = problemModalStore(state => [
    state.nativeInputLocation,
  ]);

  const canvas = useRef<HTMLCanvasElement>(null);
  const touchStartPosRef = useRef<{ x: number; y: number } | null>(null);

  const handleMouseDown = (event: React.MouseEvent): void => {
    problemCanvasOwner.mouseDown(event.nativeEvent);
  };

  const handleMouseMove = (event: React.MouseEvent): void => {
    problemCanvasOwner.mouseMove(event.nativeEvent);
  };

  const handleMouseUp = (event: React.MouseEvent): void => {
    problemCanvasOwner.mouseUp(event.nativeEvent);
  };

  const handleTouchStart = (event: TouchEvent): void => {
    if (event.touches.length > 1 || !canvas.current) {
      touchStartPosRef.current = null;

      return;
    }

    problemCanvasOwner.touchStart(event);

    if (problemCanvasOwner.isDragging()) {
      touchStartPosRef.current = null;
      event.stopPropagation();
      event.preventDefault();

      return;
    }

    const touch = event.touches[0];

    touchStartPosRef.current = { x: touch.clientX, y: touch.clientY };
  };

  const handleTouchMove = (event: TouchEvent): void => {
    problemCanvasOwner.touchMove(event);

    if (problemCanvasOwner.isDragging()) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const handleTouchEnd = (event: TouchEvent): void => {
    problemCanvasOwner.touchEnd(event);

    if (touchStartPosRef.current) {
      const touch = event.changedTouches[0];
      const distance = Math.sqrt(
        Math.pow(touch.clientX - touchStartPosRef.current.x, 2) +
          Math.pow(touch.clientY - touchStartPosRef.current.y, 2)
      );

      if (distance < MAX_PIXELS_AWAY_FROM_START) {
        const mouseEvent = new MouseEvent('mousedown', {
          cancelable: true,
          clientX: touchStartPosRef.current.x,
          clientY: touchStartPosRef.current.y,
        });

        if (problemCanvasOwner.isDragging()) {
          problemCanvasOwner.mouseUp(mouseEvent);
          event.stopPropagation();
          event.preventDefault();
        } else if (problemCanvasOwner.mouseDown(mouseEvent)) {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    }

    touchStartPosRef.current = null;
  };

  // Note: do not attach the touch listeners via jsx. React quietly
  // converts them to 'passive', which means preventDefault() does nothing.
  useEffect(() => {
    if (!canvas.current) return;

    canvas.current.addEventListener('touchstart', handleTouchStart);
    canvas.current.addEventListener('touchmove', handleTouchMove);
    canvas.current.addEventListener('touchend', handleTouchEnd);

    // Cleanup function to remove event listeners
    return () => {
      if (!canvas.current) return;
      canvas.current.removeEventListener('touchstart', handleTouchStart);
      canvas.current.removeEventListener('touchmove', handleTouchMove);
      canvas.current.removeEventListener('touchend', handleTouchEnd);
    };
  });

  useEffect(() => {
    if (!canvas.current) {
      return;
    }

    problemCanvasOwner.setCanvas(
      canvas.current,
      problemWidth ? problemWidth : NARROW_PROBLEM_WIDTH
    );

    if (problemData) {
      problemCanvasOwner.setProblem(problemData, userId);
    }
  }, [problemWidth, problemData, userId, problemCanvasOwner]);

  // Element grabs focus on mount, and also re-acquires it if needed on mousedown.
  // Intended primarily for Mobius.
  useEffect(() => {
    if (!canvas.current || globalFocus) {
      return;
    }

    const mouseDown = (_evt: MouseEvent): void => {
      if (!canvas.current) return;
      canvas.current.focus({ preventScroll: true });
    };

    canvas.current.addEventListener('mousedown', mouseDown);

    const isEditableField = (element: Element | null) => {
      if (!element) return false;
      const tagName = element.tagName.toLowerCase();
      return (
        tagName === 'input' ||
        tagName === 'textarea' ||
        (element as HTMLElement).isContentEditable
      );
    };

    // Do not strip Mobius key focus from 'Send Message'
    if (!isEditableField(document.activeElement)) {
      canvas.current.focus({ preventScroll: true });
    }

    return () => {
      if (!canvas.current) return;

      canvas.current.removeEventListener('mousedown', mouseDown);
    };
  });

  // Control whether to pass along key events to the ProblemCanvasOwner,
  // based on whether any modals are showing.
  useEffect(() => {
    if (!canvas.current) {
      return;
    }

    const keyDown = (evt: KeyboardEvent): void => {
      // TAB: Consumed because otherwise, the tab key when the native input dialog is up
      // will move focus to the next element (the hamburger menu)
      // and then pressing enter will both submit the current getter and open the menu.
      // ENTER: Consumed because otherwise, the hamburger menu may open (opens if you've
      // recently clicked on it, then clicked off to close it: it still has focus).
      // SPACE: Consumed because otherwise, the problem canvas may scroll down.
      if (evt.key === 'Tab' || evt.key === 'Enter' || evt.key === ' ') {
        evt.preventDefault();
      }

      if (isShowingAnyModal) return;

      // SPACE: Consumed because otherwise, the problem canvas may scroll down.
      // We don't do this earlier because we want to allow the space key to be delivered
      // to the native input dialog.
      if (evt.key === ' ') {
        evt.preventDefault();
      }

      problemCanvasOwner.keyDown(evt);
    };

    if (!globalFocus) {
      canvas.current.addEventListener('keydown', keyDown);
    } else {
      window.addEventListener('keydown', keyDown);
    }

    return () => {
      if (globalFocus) window.removeEventListener('keydown', keyDown);
      else if (canvas.current)
        canvas.current.removeEventListener('keydown', keyDown);
    };
  }, [problemCanvasOwner, globalFocus, isShowingAnyModal]);

  // ProblemCanvasOwner needs to monitor for 'off-canvas' end of dragging
  useEffect(() => {
    document.addEventListener('mouseup', problemCanvasOwner.stopDragging);
    document.addEventListener('touchend', problemCanvasOwner.stopDragging);

    return () => {
      document.removeEventListener('mouseup', problemCanvasOwner.stopDragging);
      document.removeEventListener('touchend', problemCanvasOwner.stopDragging);
    };
  }, [problemCanvasOwner]);

  return (
    <div
      className={animates ? 'supports-animated-replacement' : ''}
      style={{ position: 'relative', display: 'flex' }}
    >
      {/* className gives us a hook for Testim */}
      <canvas
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        ref={canvas}
        className="gmm-canvas"
        tabIndex={0}
        style={{ outline: 0 }}
      />
      <ProblemModals />
      {/* NativeInputBox is a modal, but must be here instead of problemModals
          in order to anchor it's positioning on the enclosing ProblemCanvas div. 
          The rest of the problemModals are in a 'portal', so unable to anchor on
          canvas location -- which is fine for them. */}
      {!!nativeInputLocation && <NativeInputBox />}
    </div>
  );
};

export default ProblemCanvas;
