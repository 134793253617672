import { logHistory } from '../utils';

export const MESSAGE_CHECK_QUEUE_EVICTION_TIMEOUT_MS = 3000;

let callNumber = 0;

export interface APIObserver {
  done(): void;
}

export type APICall = (observer: APIObserver) => void;

export interface QueuedAPICall {
  apiCall: APICall;
  description: string;
  // Evict this call if it takes this long
  evictionTimeout?: number;
  timeoutHandle?: NodeJS.Timeout;
  // Skip call if invalid (and execute onCancellation if it exists)
  isInvalid?: () => boolean;
  onCancellation?: () => void;
  callNumber?: number;
  start?: Date;
}

class APIQueue {
  queue = new Array<QueuedAPICall>();
  current: QueuedAPICall | undefined;

  add(apiCall: QueuedAPICall): void {
    this.queue.push(apiCall);
    apiCall.callNumber = callNumber;
    callNumber++;
    if (!this.current) this.next();
  }

  getObserver(task: QueuedAPICall): APIObserver {
    return {
      done: () => {
        if (task.timeoutHandle) {
          clearTimeout(task.timeoutHandle);
        }

        const elapsed = new Date().getTime() - task.start!.getTime();

        logHistory(`API call ${task.description} took ${elapsed}ms`);

        if (this.current?.callNumber === task.callNumber) {
          this.next();
        }
      },
    };
  }

  next(): void {
    this.current = undefined;

    const task = this.queue.shift();

    if (!task) {
      return;
    }

    if (task.isInvalid?.()) {
      task.onCancellation?.();
      this.next();

      return;
    }

    task.start = new Date();
    this.current = task;
    task.apiCall(this.getObserver(task));

    if (task.evictionTimeout) {
      task.timeoutHandle = this.monitorForEviction(task);
    }
  }

  monitorForEviction(task: QueuedAPICall): NodeJS.Timeout {
    return setTimeout(() => {
      if (this.current?.callNumber === task.callNumber) {
        logHistory(
          `API call ${task.description} is taking too long, advancing queue`
        );
        this.next();
      }
    }, task.evictionTimeout);
  }

  isProcessing(): boolean {
    return !!this.current;
  }

  clear(): void {
    this.queue = [];
  }
}

export const apiQueue = new APIQueue();
