import { bannerState } from '../../studentApp/stores';
import {
  SMILEYS,
  studentAppModalState,
} from '../../studentApp/stores/studentAppModalStore';
import { userState } from '../../studentApp/stores/userStore';

const IMAGE_PATH = 'GMM-images/';
const SEASONAL_IMAGES_PATH = IMAGE_PATH + 'seasonalimages/';

let smileyTimeoutTimer: NodeJS.Timeout | undefined;
let smileyIntervalTimer: NodeJS.Timeout | undefined;
let crowning = false;

export const RewardImage = {
  SMILEY: IMAGE_PATH + 'Smiley.png',
  CROWN: IMAGE_PATH + 'crown.png',
  YOU_HAVE_THE_MOST_POINTS: IMAGE_PATH + 'youhavethemostpoints.png',
  TURKEY: SEASONAL_IMAGES_PATH + 'Turkey.png',
  CANDYCANE: SEASONAL_IMAGES_PATH + 'candycane.png',
  SNOWFLAKE: SEASONAL_IMAGES_PATH + 'snowflake.png',
  PUMPKIN: SEASONAL_IMAGES_PATH + 'pumpkin.png',
  PUMPKIN2: SEASONAL_IMAGES_PATH + 'pumpkin2.png',
  HEART: SEASONAL_IMAGES_PATH + 'heart.png',
  PIE: SEASONAL_IMAGES_PATH + 'pie.png',
  PI: SEASONAL_IMAGES_PATH + 'pi.png',
  TACO: SEASONAL_IMAGES_PATH + 'taco.png',
};

enum Month {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

enum DayOfWeek {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

/**
 *
 * @param rewardImagePool relative paths to images to use for this session
 * @param percentOfImages percentage of each image to use (of our '20 smileys')
 */
export function setRewardImage(
  rewardImagePool?: string[],
  percentOfImages?: number[]
): void {
  const rewardImages = getRewardImage(
    new Date(),
    rewardImagePool,
    percentOfImages
  );

  updateRewardUI(rewardImages);
}

export function getRewardImage(
  date: Date,
  rewardImagePool?: string[],
  percentOfImages?: number[]
): string[] {
  const coreImage = crowning ? RewardImage.CROWN : RewardImage.SMILEY;

  if (!rewardImagePool) {
    const month = date.getMonth();
    const dayOfMonth = date.getDate();
    const dayOfWeek = date.getDay();

    const thanksgivingDay = THANKSGIVING_YEAR_TO_DAY[date.getFullYear()];

    // Thanksgiving
    if (
      month === Month.NOVEMBER &&
      dayOfMonth > thanksgivingDay - 4 &&
      dayOfMonth <= thanksgivingDay
    ) {
      rewardImagePool = [coreImage, RewardImage.TURKEY];
      // 80% default image, 20% turkey
      if (dayOfMonth === thanksgivingDay - 3) percentOfImages = [0.8];
      // 70% default image, 30% turkey
      else if (dayOfMonth === thanksgivingDay - 2) percentOfImages = [0.3];
      // 100% turkey
      else percentOfImages = [0];
      // Christmas
    } else if (month === Month.DECEMBER && dayOfMonth > 17 && dayOfMonth < 26) {
      rewardImagePool = [
        coreImage,
        RewardImage.CANDYCANE,
        RewardImage.SNOWFLAKE,
      ];
      // 90% default image, 5% candy cane, 5% snowflake
      if (dayOfMonth === 18) percentOfImages = [0.9, 0.95];
      // 80% default image, 10% candy cane, 10% snowflake
      else if (dayOfMonth === 19) percentOfImages = [0.8, 0.9];
      // 60% default image, 20% candy cane, 20% snowflake
      else if (dayOfMonth === 20) percentOfImages = [0.6, 0.8];
      // 40% default image, 30% candy cane, 30% snowflake
      else if (dayOfMonth === 21) percentOfImages = [0.4, 0.7];
      // 20% default image, 40% candy cane, 40% snowflake
      else if (dayOfMonth === 22) percentOfImages = [0.2, 0.6];
      // 50% candy cane, 50% snowflake
      else percentOfImages = [0, 0.5];
      // Halloween
    } else if (month === Month.OCTOBER && dayOfMonth >= 28) {
      rewardImagePool = [coreImage, RewardImage.PUMPKIN, RewardImage.PUMPKIN2];
      // 33% default image, 33% pumpkin, 34% pumpkin2
      percentOfImages = [0.33, 0.66];
      // Valentine's Day
    } else if (
      month === Month.FEBRUARY &&
      dayOfMonth >= 12 &&
      dayOfMonth <= 14
    ) {
      rewardImagePool = [coreImage, RewardImage.HEART];
      // 50% default image, 50% heart
      percentOfImages = [0.5];
      // Pi Day
    } else if (month === Month.MARCH && dayOfMonth === 14) {
      rewardImagePool = [coreImage, RewardImage.PIE, RewardImage.PI];
      // 33% default image, 33% pie, 34% pi
      percentOfImages = [0.33, 0.66];
      // Taco Tuesday
    } else if (dayOfWeek === DayOfWeek.TUESDAY) {
      rewardImagePool = [coreImage, RewardImage.TACO];
      // 50% default image, 50% taco
      percentOfImages = [0.5];
    }

    if (!rewardImagePool) {
      if (!crowning) {
        rewardImagePool = [coreImage];
      } else {
        rewardImagePool = [coreImage, RewardImage.YOU_HAVE_THE_MOST_POINTS];
        // 85% crown, 15% youhavethemostpoints
        percentOfImages = [0.85];
      }
    }
  }

  if (!percentOfImages) {
    percentOfImages = [];
    const inc = 1 / rewardImagePool.length;

    for (let z = inc; z < 1; z += inc) {
      percentOfImages.push(z);
    }
  }

  let nextPercent = 0;
  const rewardImages = Array<string>();

  for (let a = 0; a < 20; a++) {
    let rewardImage = undefined;

    nextPercent += 0.04999999;

    for (let x = 0; x < rewardImagePool.length - 1; x++) {
      if (nextPercent < percentOfImages[x]) {
        rewardImage = rewardImagePool[x];
        break;
      }
    }

    if (!rewardImage) rewardImage = rewardImagePool[rewardImagePool.length - 1];

    rewardImages.push(rewardImage);
  }

  return rewardImages;
}

function updateRewardUI(rewardImages: string[]): void {
  rewardImages.forEach((rewardImage, index) => {
    const imageElement =
      "<img id='smiley" +
      index +
      "' src='" +
      rewardImage +
      "' style='position:fixed'>";

    $('#smileys').append(imageElement);
  });
}

export function scatterSmileys(): void {
  const w = document.documentElement.clientWidth;
  const h = document.documentElement.clientHeight;

  $('#smileys')
    .children()
    .each(function () {
      const $el = $(this);
      const x = getInt(-10, w - $el.width()! + 10);
      const y = getInt(-10, h - $el.height()! - 10);

      // @ts-ignore No idea why this is set
      $el[0].gmmY = y;
      $el.css('left', x + 'px');
      $el.css('top', y + 'px');
    });
}

export function showSmileys(): void {
  const pointsToday = bannerState().pointsToday;
  const updatedCrowning =
    pointsToday > 0 && bannerState().classHighestToday <= pointsToday;

  if (updatedCrowning != crowning) {
    crowning = updatedCrowning && !userState().neverCrown;
    setRewardImage();
  }

  studentAppModalState().setCurrentModal(SMILEYS);

  const stopSmileys = () => {
    studentAppModalState().stopSmileys();
    turnOffSmileyTimers();
  };

  // In case there is a prior smiley animation running ("shouldn't" be possible)
  turnOffSmileyTimers();

  smileyIntervalTimer = setInterval(moveSmileys, 100);
  smileyTimeoutTimer = setTimeout(stopSmileys, 2000);
}

function moveSmileys(): void {
  const clientHeight = document.documentElement.clientHeight;
  const inc = clientHeight * 0.05;

  $('#smileys')
    .children()
    .each(function () {
      const $el = $(this);
      // @ts-ignore No idea why this is set
      let r = $el[0].gmmY || 0;

      r += inc;

      if (r > clientHeight) {
        r = -10;
        const l = getInt(
          0,
          document.documentElement.clientWidth - $el.height()!
        );

        $el.css('left', l + 'px');
      }

      // @ts-ignore No idea why this is set
      $el[0].gmmY = r;
      $el.css('top', r + 'px');
    });
}

export function turnOffSmileyTimers(): void {
  clearInterval(smileyIntervalTimer);
  clearTimeout(smileyTimeoutTimer);
}

function getInt(a: number, b: number): number {
  return Math.floor(Math.random() * (b - a + 1) + a);
}

const THANKSGIVING_YEAR_TO_DAY: Record<number, number> = {
  2023: 23,
  2024: 28,
  2025: 27,
  2026: 26,
  2027: 25,
  2028: 23,
  2029: 22,
  2030: 28,
  2031: 27,
  2032: 25,
  2033: 24,
  2034: 23,
  2035: 22,
  2036: 27,
  2037: 26,
  2038: 25,
  2039: 24,
  2040: 22,
  2041: 28,
  2042: 27,
  2043: 26,
  2044: 24,
  2045: 23,
  2046: 22,
  2047: 28,
  2048: 26,
  2049: 25,
  2050: 24,
  2051: 23,
  2052: 28,
  2053: 27,
  2054: 26,
  2055: 25,
  2056: 23,
  2057: 22,
  2058: 28,
  2059: 27,
  2060: 25,
  2061: 24,
  2062: 23,
  2063: 22,
  2064: 27,
  2065: 26,
  2066: 25,
  2067: 24,
  2068: 22,
  2069: 28,
  2070: 27,
  2071: 26,
  2072: 24,
  2073: 23,
  2074: 22,
  2075: 28,
  2076: 26,
  2077: 25,
  2078: 24,
  2079: 23,
  2080: 28,
  2081: 27,
  2082: 26,
  2083: 25,
  2084: 23,
  2085: 22,
  2086: 28,
  2087: 27,
  2088: 25,
  2089: 24,
  2090: 23,
  2091: 22,
  2092: 27,
  2093: 26,
  2094: 25,
  2095: 24,
  2096: 22,
  2097: 28,
  2098: 27,
  2099: 26,
  2100: 25,
  2101: 24,
  2102: 23,
  2103: 22,
  2104: 27,
  2105: 26,
  2106: 25,
  2107: 24,
  2108: 22,
  2109: 28,
  2110: 27,
  2111: 26,
  2112: 24,
  2113: 23,
  2114: 22,
  2115: 28,
  2116: 26,
  2117: 25,
  2118: 24,
  2119: 23,
  2120: 28,
  2121: 27,
  2122: 26,
  2123: 25,
};
