/* eslint-disable padding-line-between-statements */
import { ProblemCanvasOwner, buildProblemCanvasOwner } from '@gmm/problem';

import { problemContext, pubsub } from '../legacy';
import { Any } from '../types';

import { userState } from './userStore';
import { workState } from './workStore';

let apiUrl = '';

export interface State {
  apiUrl: string;
  lastMsgId?: string;
  lastLoginTry?: number;
  pingPoliceId?: NodeJS.Timeout;
  nextMsgId: number;
  timeSeenStart?: Date;
  timeStuck?: number;
  gmm?: ProblemCanvasOwner;
  snackbarMessage:
    | string
    | { autoHideDuration?: number | null; message: string };
}

type GameMaster = Any;

declare global {
  interface Window {
    // Can't be removed from global/document-level reference until we change
    // games from old js to ts and stop hot-loading them. Maybe never.
    gameMaster?: GameMaster;
    studentApp: State;
    dev: (start: boolean) => void;
  }
}

window.studentApp = {
  apiUrl: process.env.JAVA_URL ?? '',
  nextMsgId: 2,
  snackbarMessage: '',
};

export const getGuid = (): string => userState().guid;
export const getSicId = (): number => userState().sicId;
export const getSs = (): number => userState().redisSessionId;
export const getUserId = (): number => userState().userId;
export const getUsername = (): string => userState().userName;
export const getIsTest = (): boolean => workState().currentWork.type === 'EXAM';

export const getApiUrl = (): string => apiUrl;
export const setApiUrl = (value: string): void => {
  apiUrl = value;
};

// The 'problemjs' object -- the one from the huge 'index.js' file
// (package '@gmm/problem, defined as ProblemCanvas in index.d.ts') --
// exposes an interface so that the student app can call its methods.
// For example, the student app can call receiveProblem.
let problemCanvasOwner: ProblemCanvasOwner | undefined;

export const getGmm = (): ProblemCanvasOwner => {
  if (!problemCanvasOwner) {
    problemCanvasOwner = buildProblemCanvasOwner({
      problemContext,
      isStudent: true,
    });
  }
  return problemCanvasOwner;
};

export const getLastMsgId = (): State['lastMsgId'] =>
  window.studentApp.lastMsgId;
export const setLastMsgId = (value: State['lastMsgId']): void => {
  window.studentApp.lastMsgId = value;
};

export const getLastLoginTry = (): State['lastLoginTry'] =>
  window.studentApp.lastLoginTry;
export const setLastLoginTry = (value: State['lastLoginTry']): void => {
  window.studentApp.lastLoginTry = value;
};

export const getSnackbarMessage = (): State['snackbarMessage'] => {
  return window.studentApp.snackbarMessage;
};
export const setSnackbarMessage = (value: State['snackbarMessage']): void => {
  window.studentApp.snackbarMessage = value;
  pubsub.publish('snackbarMessage', value);
};

export const getTimeSeenStart = (): State['timeSeenStart'] =>
  window.studentApp.timeSeenStart;
export const setTimeSeenStart = (value: State['timeSeenStart']): void => {
  window.studentApp.timeSeenStart = value;
};

export const getTimeStuck = (): State['timeStuck'] =>
  window.studentApp.timeStuck;
export const setTimeStuck = (value: State['timeStuck']): void => {
  window.studentApp.timeStuck = value;
};
