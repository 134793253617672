import axios from 'axios';

import { ProblemData } from '@gmm/problem';

import { problemState } from '../../stores';
import {
  getApiUrl,
  getGuid,
  getSicId,
  getSs,
  getUsername,
} from '../../stores/globalState';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { reboot } from '../../utils';
import { APIObserver, apiQueue } from '../queue';
import { handleSessionClosed, toBody } from '../utils';

interface RestoreOptions {
  onSuccess: (data: ProblemData) => void;
  onError: (error: unknown) => void;
}

export async function sendRestoreRequest(
  id: string,
  isTest = false,
  { onSuccess, onError }: RestoreOptions
): Promise<void> {
  const apiCall = (apiObserver: APIObserver) => {
    sendRestoreRequestHelper(apiObserver, id, isTest, { onSuccess, onError });
  };

  apiQueue.add({
    apiCall,
    isInvalid: () => !problemState().hasSquare(id),
    description: `ServletRestore for ${id}`,
  });
}

async function sendRestoreRequestHelper(
  apiObserver: APIObserver,
  id: string,
  isTest = false,
  { onSuccess, onError }: RestoreOptions
): Promise<void> {
  try {
    studentAppModalState().setLoading(true, 'ServletRestore');

    const url = `${getApiUrl()}/ServletRestore`;

    const body = {
      a: new Date().getTime(),
      guid: getGuid(),
      id,
      isTest,
      sicId: getSicId(),
      ss: getSs(),
      user: getUsername(),
    };

    const { data } = await axios.post(url, toBody(body));

    if (data.sessionClosed) return handleSessionClosed();

    const isFail = data.guidFail || data.fail;

    if (isFail) reboot(isFail);

    onSuccess(data.p);
  } catch (err) {
    onError(err);
  } finally {
    studentAppModalState().setLoading(false, 'ServletRestore');
    apiObserver.done();
  }
}
