import { CSSProperties, FC } from 'react';

import {
  NARROW_PROBLEM_WIDTH,
  PROBLEM_WIDTH,
  ProblemCanvas,
  STUDENT_PROBLEM_BACKCOLOR,
} from '@gmm/problem';

import {
  useProcessedBannerStore,
  useProcessedWorkStore,
} from '../../../studentApp/hooks';
import { useLayout } from '../../../studentApp/hooks/useLayout';
import { getGmm } from '../../../studentApp/stores/globalState';
import {
  BOX_SHADOW,
  LANDSCAPE_PROBLEM_WIDTH_PERCENTAGE,
} from '../../constants';

import { ExamTop } from './examTop';
import { NormalTop } from './normalTop';

const landscape: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  height: '100%',
  width: `${LANDSCAPE_PROBLEM_WIDTH_PERCENTAGE}%`,
  paddingBottom: '20px',
};

const vertical: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'left',
  flexGrow: 1,
  height: '100%',
  width: '100%',
  minWidth: '100%',
  maxWidth: '100%',
};

const topRowStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const getProblemCanvasHolderStyle = (isLandscape: boolean): CSSProperties => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: STUDENT_PROBLEM_BACKCOLOR,
  paddingTop: '5px',
  boxShadow: !isLandscape ? undefined : BOX_SHADOW,
  // Scroll magic. If we limit height, but also use flexGrow 1,
  // we get well-behaved scrollbars for landscape and portrait.
  height: !isLandscape ? undefined : '50px',
});

export const ProblemRegion: FC = () => {
  const { showSquares } = useProcessedBannerStore();
  const { isMobile, isLandscape } = useLayout();
  const currentWorkType = useProcessedWorkStore().currentWorkType;

  const style = isLandscape ? landscape : vertical;

  return (
    <div style={{ ...style, marginLeft: showSquares ? 'auto' : '' }}>
      <div style={topRowStyle}>
        {currentWorkType !== 'EXAM' && <NormalTop />}
        {currentWorkType === 'EXAM' && <ExamTop />}
      </div>

      <div
        id={'scrollableProblemHolder'}
        className="scrollable scrollable-problem"
        style={getProblemCanvasHolderStyle(isLandscape)}
      >
        <ProblemCanvas
          problemCanvasOwner={getGmm()}
          problemWidth={isMobile ? NARROW_PROBLEM_WIDTH : PROBLEM_WIDTH}
          animates={true}
        />
      </div>
    </div>
  );
};
