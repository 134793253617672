import axios from 'axios';

import { getApiUrl, getGuid, getSs } from '../../stores/globalState';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { userState } from '../../stores/userStore';
import { gmmAlert, logHistory, packageGenericError } from '../../utils';
import { alerts } from '../alerts';
import { APIObserver, apiQueue } from '../queue';
import { sendErrorToServer } from '../sendError';
import { getAxiosErrorMessage, isAxiosError, toBody } from '../utils';

import { handleSendObjectResponse } from './responseHandler';
import { FAULT_TOLERANT_SEND_OBJECT_TYPES, SendObjectType } from './types';

interface SendObjectOptions<Data> {
  onSuccess?: (data: Data) => void;
  skipToggleLoading?: boolean;
}

export async function sendObject<Data>(
  {
    a = new Date().getTime(),
    ss = getSs(),
    user = userState().userName,
    guid = getGuid(),
    ...obj
  }: SendObjectType,
  { onSuccess, skipToggleLoading }: SendObjectOptions<Data> = {}
): Promise<void> {
  const apiCall = (apiObserver: APIObserver) => {
    sendObjectHelper(
      apiObserver,
      { a, ss, user, guid, ...obj },
      {
        onSuccess,
        skipToggleLoading,
      }
    );
  };

  apiQueue.add({
    apiCall,
    description: `SendObject type: ${obj.type}`,
  });
}

async function sendObjectHelper<Data>(
  apiObserver: APIObserver,
  {
    a = new Date().getTime(),
    ss = getSs(),
    user = userState().userName,
    guid = getGuid(),
    ...obj
  }: SendObjectType,
  { onSuccess, skipToggleLoading }: SendObjectOptions<Data> = {}
): Promise<void> {
  try {
    if (!obj?.type) {
      console.error('No object to send!');

      return;
    }

    const payload = {
      ...obj,
      a,
      ss,
      user,
      guid,
    };

    const serialized = toBody(payload);

    logHistory(`sendObject ${JSON.stringify(payload)}`);

    if (!skipToggleLoading)
      studentAppModalState().setLoading(true, 'SendObject');

    const url = `${getApiUrl()}/SendObject`;

    // Please leave in commented retry code. Hoping to use it in when retries are safe to use WF-side.
    // const axiosInstance = getAxiosRetryInstance({
    //   retries: 1,
    //   retryDelay: () => 200,
    //   retryCondition: _error => true,
    //   onRetry: (retryCount, _error, _requestConfig) => {
    //     logHistory(`sendObject retry ${retryCount} for type ${obj.type}`);
    //   },
    // });

    // const { data } = await axiosInstance.post(url, serialized);

    const { data } = await axios.post(url, serialized);

    await handleSendObjectResponse(data);

    onSuccess?.(data);

    return;
  } catch (err) {
    if (FAULT_TOLERANT_SEND_OBJECT_TYPES.includes(obj.type)) {
      logHistory(`sendObject failed on type ${obj.type}, ignoring`);

      return;
    }

    gmmAlert(alerts.connectionTrouble);

    if (isAxiosError(err)) {
      sendErrorToServer(
        getAxiosErrorMessage(`SendObject type: ${obj.type}`, err),
        true
      );

      return;
    }

    sendErrorToServer(
      `SendObject logic fail. 
      Outbound request: ${JSON.stringify(obj, null, 2)}
      Error: ${packageGenericError(err)}`,
      true
    );
  } finally {
    studentAppModalState().setLoading(false, 'SendObject');
    apiObserver.done();
  }
}
