import { unstable_batchedUpdates } from 'react-dom';

import {
  AlertOptions,
  ProblemData,
  cancelReadAloud,
  closeAllProblemDialogs,
} from '@gmm/problem';

import { MIN_LANDSCAPE_WIDTH, MIN_PORTRAIT_WIDTH } from '../../cnusr/constants';
import {
  LoginRequest,
  alerts,
  login,
  postHandInExam,
  postIncrementTimeSeen,
  sendRaisedHand,
} from '../api';
import { apiQueue } from '../api/queue';
import { receiveProblem } from '../api/responseHandlerShared';
import { getType, getWorkProgress } from '../hooks/useProcessedWorkStore';
import {
  activity,
  resetActivityMonitor,
  turnOffInactiveTimer,
  turnOffPingTimers,
  turnOffSmileyTimers,
} from '../legacy';
import { bannerState, problemState } from '../stores';
import {
  getGmm,
  getLastMsgId,
  getTimeSeenStart,
  setTimeSeenStart,
} from '../stores/globalState';
import { problemJsonMap } from '../stores/problemJsonMap';
import {
  SHOW_ANSWER,
  studentAppModalState,
} from '../stores/studentAppModalStore';
import { userState } from '../stores/userStore';
import { workState } from '../stores/workStore';
import { NO_WORK } from '../types';

import { getTimeStampWithMillis } from './getTimestamp';

const logs = new Array<string>();
const MAX_LOG_LINES = 100;

interface LoginInteral {
  showSmileys?: boolean;
  switchClasId?: number;
  alertOptions?: AlertOptions;
}

export function loginInternal({
  showSmileys,
  switchClasId,
  alertOptions,
}: LoginInteral = {}): void {
  studentAppModalState().setLoading(true, 'login');

  unstable_batchedUpdates(() => {
    apiQueue.clear();
    workState().setCurrentWork(NO_WORK);
    studentAppModalState().clear();
    problemState().clear();
    problemJsonMap.clear();
    getGmm()?.setToBlank();
    studentAppModalState().closeAll();
    turnOffAllTimers();
    resetActivityMonitor();
    bannerState().setDailyGoal(0);

    if (bannerState().isHandRaised) {
      bannerState().setIsHandRaised(false);
      // Tell the server
      sendRaisedHand(false);
    }

    const packet: LoginRequest = {
      username: userState().userName || '',
      password: userState().password || '',
      loginInternal: true,
      ss: userState().redisSessionId,
      lastMsgId: getLastMsgId(),
      // prevent cache
      a: new Date().getTime(),
    };

    if (switchClasId) packet.switchClasId = switchClasId;
    login(packet, showSmileys);

    if (alertOptions) gmmAlert(alertOptions);
  });
}

export function harvestTimeSeen(send?: boolean): number | void {
  const timeSeenStart = getTimeSeenStart();

  if (!timeSeenStart) {
    setTimeSeenStart(new Date());

    return;
  }

  const delta = Math.max(0, new Date().getTime() - timeSeenStart.getTime());

  setTimeSeenStart(new Date());

  if (send && delta > 1500) {
    postIncrementTimeSeen({
      problemId: problemState().selectedID,
      increment: delta,
    });
  }

  return delta;
}

export const handleSevereError = (msg: string): void => {
  studentAppModalState().setLoading(false, 'nuclear');

  gmmAlert(alerts.severeError(msg));

  return;
};

export function reboot(msg: string): void {
  logHistory(
    `Rebooting (return to login after msg dialog closes) | msg: ${msg}`
  );

  gmmAlert({
    msg,
    reload: true,
    top: 'GMM',
  });

  studentAppModalState().setLoading(false, 'nuclear');
  studentAppModalState().setExamLoading(false);
  turnOffAllTimers();
  apiQueue.clear();

  return;
}

export function turnOffAllTimers(): void {
  turnOffPingTimers();
  turnOffInactiveTimer();
  turnOffSmileyTimers();
}

export function logHistory(str: string): void {
  logs.push(`${getTimeStampWithMillis()} ${str}`);
}

export function logWork(pre: string): void {
  const work = workState().currentWork;

  logHistory(
    `${pre} 
    Work Type: ${getType(work)}  
    Work Name: ${work.name}  
    Work ID: ${work.workId}  
    Progress: ${getWorkProgress(work)}`
  );
}

export function logRestoreIds(pre: string): void {
  const restoreIds = Object.keys(problemState().problems).join(', ');

  logHistory(`${pre} Restore Ids: ${restoreIds}`);
}

export function getHistory(): string {
  const recentLogs = logs.slice(-MAX_LOG_LINES);

  return recentLogs.join('\n');
}

export function processAjaxFailure(jqXhr: JQuery.jqXHR<any>): boolean {
  switch (jqXhr.status) {
    case 401: {
      reboot('Session Expired');

      return true;
    }

    default: {
      return false;
    }
  }
}

interface ReplaceProblem {
  id: number;
  p: ProblemData;
}

export interface ShowAnswer {
  // json for display of answer to current problem on showAnswerModal
  answerJson: ProblemData;

  // Use this to replace current problem when student is done looking at answer.
  replaceProblem: ReplaceProblem;

  replacementsUsedToday: number;
}

export function showAnswer({
  answerJson,
  replaceProblem,
  replacementsUsedToday,
}: ShowAnswer): void {
  if (!answerJson || !replaceProblem) return;

  bannerState().setReplacementsUsedToday(replacementsUsedToday);

  // tuck away the problem to be replaced when student is done looking at answer
  studentAppModalState().setReplacementProblem({
    id: replaceProblem.id,
    problem: replaceProblem.p,
    performingTargetedOverride: true,
  });

  studentAppModalState().setAnswerToCurrentProblem(answerJson);

  studentAppModalState().setCurrentModal(SHOW_ANSWER);
}

export function animateReplaceProblem(): void {
  const $canvas = $('.supports-animated-replacement');

  // Ensure there is only one listener
  $canvas.off('transitionend').on('transitionend', finishAnimateReplaceProblem);

  $canvas.css({
    transition: 'transform .6s',
    'transform-style': 'preserve-3d',
    transform: 'translateX(-50%) rotateY(90deg)',
  });
}

function finishAnimateReplaceProblem(): void {
  // current problem state switched to replacement
  const problem = studentAppModalState().replacementProblem;

  if (problem) {
    problemState().resetSquare(`${problem.id}`);
    receiveProblem(problem);
    studentAppModalState().setReplacementProblem(undefined);
  }

  const $canvas = $('.supports-animated-replacement');

  $canvas.css('transform', 'translateX(0%) rotateY(0deg)');
}

export function gmmAlert({
  msg,
  top = '',
  style = 'green',
  reload = false,
  reloadIsInternal = false,
  removeWhenAnotherDialogShows = false,
  removeProblemRelatedDialogs = false,
  problemRelated = false,
  killDuplicates = false,
}: AlertOptions): void {
  cancelReadAloud();

  if (msg.indexOf('Invalid:') > -1 && (!top || top === 'Message')) {
    const i = msg.indexOf('Invalid:');

    msg = msg.substring(i + 9);
    top = 'Invalid';
  } else if (msg.indexOf('FYI:') > -1 && (!top || top === 'Message')) {
    const i = msg.indexOf('FYI:');

    msg = msg.substring(i + 5);
    top = 'FYI';
  }

  if (!top || top === '') top = 'GMM';

  if (removeProblemRelatedDialogs) {
    // problem-related dialogs owned by student app
    studentAppModalState().closeAllProblemDialogs();
    // problem-related dialogs owned by @gmm/problem package
    closeAllProblemDialogs();
  }

  studentAppModalState().addStackableDialog({
    msg,
    style,
    top,
    reload,
    reloadIsInternal,
    removeWhenAnotherDialogShows,
    problemRelated,
    killDuplicates,
  });

  if (reload) {
    turnOffAllTimers();
    setTimeout(window.location.reload, 10 * 60000);
  }
}

export async function submitTest(handIn?: boolean): Promise<void> {
  activity();

  const work = workState().currentWork;

  if (work.type !== 'EXAM') return;

  if (handIn) await postHandInExam(work.sitId!);

  loginInternal();
}

// We have this sort of info from useLayout, but sometimes need it outside of
// React components.
export const isMobile = (): boolean => {
  return window.innerWidth < MIN_PORTRAIT_WIDTH;
};

export const isLandscape = (): boolean => {
  return window.innerWidth >= MIN_LANDSCAPE_WIDTH;
};

export const allowGames = (): boolean => {
  const hasGames = studentAppModalState().availableGames.length > 0;
  const { blockGames, blockGamesClass } = bannerState();

  return hasGames && !blockGames && !blockGamesClass;
};

export const packageGenericError = (err: any): string => {
  if (err instanceof Error)
    return `
    message: ${err.message || 'No message'}
    stack: ${err.stack || 'No stack'}`;

  return JSON.stringify(err, null, 2);
};
