import { isEmpty, omit } from 'lodash';
import { create } from 'zustand';
import { devtools as dev } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { Score } from '@gmm/problem';

import {
  isAxiosError,
  postOrderBy,
  postRestoreIdSelected,
  postSawRestoreTest,
  sendErrorToServer,
  sendSkipsUpdate,
  getAxiosErrorMessage,
} from '../api';
import { alerts } from '../api/alerts';
import { receiveProblem, unlockAll } from '../api/responseHandlerShared';
import { sendRestoreRequest } from '../api/sendRestore';
import { activity } from '../legacy/activityMonitor';
import {
  DateString,
  ExamProblems,
  ID,
  NormalProblems,
  OrderOption,
  ProblemObjects,
  Problems,
  ProblemSet,
  Proficiency,
  NewWorkProblems,
  DEFAULT_ORDER_BY,
} from '../types';
import {
  gmmAlert,
  getOrderedIDs,
  isExamProblem,
  ProblemShared,
  getExamOrderedIds,
  isNormalProblem,
  colorData,
} from '../utils';
import {
  harvestTimeSeen,
  isLandscape,
  logHistory,
  loginInternal,
  logRestoreIds,
  packageGenericError,
} from '../utils/gmmUtils';

import { bannerState } from './bannerStore';
import { getGmm, getIsTest } from './globalState';
import { problemJsonMap } from './problemJsonMap';
import { studentAppModalState } from './studentAppModalStore';

export type ProblemType =
  | 'NEW_SKILL'
  | 'SPIRAL_REVIEW'
  | 'EXAM_CORRECTIONS'
  | 'PRACTICE_CORRECTIONS';

export type NormalProblem = {
  skillId: number;
  daysSince: number;
  firstTry: boolean;
  followUp: boolean;
  hasBeenTried: boolean;
  id: string;
  isUnfixedExamCorrection: boolean;
  type: ProblemType;
  lastAttempt: number;
  lastCorrectDate: 'Never' | 'Today' | 'Yesterday' | DateString;
  lastFive: Score;
  lastTen: Score;
  lastThree: Score;
  lvl: Proficiency;
  myAllTime: Score;
  penalty: number;
  rawScore: number;
  // How many times the student has looked at this problem,
  // then clicked on another Skill
  skips: number;
  status?: 'right' | 'wrong';
  assigned?: boolean;
  dateLearned?: string;

  // problems for Corrections know what test number they correspond to
  number?: number;
};

export const initialNormalProblem = {
  skillId: 0,
  daysSince: 7,
  firstTry: true,
  followUp: false,
  hasBeenTried: false,
  id: '1',
  isUnfixedExamCorrection: false,
  lastAttempt: 0,
  lastCorrectDate: 'Never' as DateString,
  lastFive: 'N/A' as Score,
  lastTen: 'N/A' as Score,
  lastThree: 'N/A' as Score,
  lvl: 'red' as Proficiency,
  myAllTime: 'N/A' as Score,
  penalty: 0,
  rawScore: 0,
  skips: 0,
};

export type ExamProblem = {
  id: string;
  locked: boolean;
  notValid: boolean;
  order: number;
  ready: boolean;
  seen: boolean;
  testNumber: number;
  uncertain?: boolean;
};

export const initialExamProblem = {
  id: '',
  locked: false,
  notValid: false,
  order: 1,
  ready: false,
  testNumber: 1,
  seen: false,
};

type LevelingUp = {
  id: string;
  lvl: Proficiency;
};

export interface ProblemStoreState {
  autoUnlockExamsProblems: boolean;
  dollars: string[];
  dollarsOnly: boolean;
  levelingUp: LevelingUp;
  lockedCount: number;
  orderBy: OrderOption;
  previousColor?: Proficiency;
  problems: Problems;
  selectedID: string;
  // As in, "I'm doing #7"
  currentExamProblemNumber?: number;
  noProblemsOnLogin: boolean;

  // Which proficiencies have triggered modal levelup animations this session?
  // We only show modal level up animations once per session per Proficiency.
  animateLevelUp: number[];
}

export interface ProblemStore extends ProblemStoreState {
  clearLevelingUp: () => void;
  setAutoUnlockExamsProblems: (autoUnlock: boolean) => void;
  setDetails: (problems: ProblemSet) => void;
  clear: () => void;
  setDollars: (dollarArray: string[]) => void;
  setDollarsOnly: (value: boolean) => void;
  setLockedCount: (lockedCount: number) => void;
  setOrderBy: (orderBy: OrderOption, updateServer?: boolean) => void;
  setPenalty: (id: string, amount: number) => void;
  getPenalties: (id: string) => number;
  setSelectedID: (id: string, type?: string) => void;
  hasSquare: (id: ID) => boolean;
  updateProblem: (problemID: string, problem: Partial<ProblemShared>) => void;
  resetSquare: (problemId: string) => void;
  setNoProblemsOnLogin: (value: boolean) => void;
}

(window as any).__REDUX_DEVTOOLS_EXTENSION__;

// Vanilla store. React will wrap in create from 'zustand'
export const problemStore = create<ProblemStore>()(
  dev(
    immer((set, get) => ({
      autoUnlockExamsProblems: true,
      dollars: [],
      dollarsOnly: false,
      lockedCount: 0,
      levelingUp: { id: '', lvl: 'red' },
      orderBy: DEFAULT_ORDER_BY,
      problems: {},
      selectedID: '',
      noProblemsOnLogin: false,
      animateLevelUp: [],
      setNoProblemsOnLogin: (value: boolean) =>
        set({ noProblemsOnLogin: value }),
      clearLevelingUp: () => set({ levelingUp: { id: '', lvl: 'red' } }),
      setAutoUnlockExamsProblems: (autoUnlock: boolean) => {
        set(draft => {
          if (!autoUnlock) {
            draft.autoUnlockExamsProblems = false;
          } else {
            draft.autoUnlockExamsProblems = true;
            Object.values(draft.problems).map(prob => {
              const problem = draft.problems[prob.id] as ExamProblem;

              problem.locked = false;
            });
          }
        });
      },
      clear: () => {
        logHistory('problemStore.clear');
        set({
          problems: {},
          dollars: [],
          selectedID: '',
          lockedCount: 0,
        });
      },

      // call with empty Record or undefined to wipe out
      // such as on login with no problems assigned to class
      setDetails: (problemSet: ProblemObjects | undefined) => {
        if (!problemSet || Object.keys(problemSet).length === 0) {
          get().clear();

          return;
        }

        const problems = Object.entries(problemSet).reduce<Problems>(
          (problemsWithId, [id, problem]) => {
            if (isExamProblem(problem)) {
              problemsWithId[id] = {
                ...problem,
                id,
                locked: !problem.unseen && !get().autoUnlockExamsProblems,
                notValid: !!problem.notValid,
                order: problem.tn,
                ready: problem.ready,
                seen: !problem.unseen,
                testNumber: problem.tn,
              } as ExamProblem;

              const examProblem = problemsWithId[id] as ExamProblem;

              if (examProblem.locked) {
                set(({ lockedCount }) => ({ lockedCount: lockedCount + 1 }));
              }
            } else {
              set({ lockedCount: 0 });
              problemsWithId[id] = {
                ...problem,
                id,
                followUp: problem.followUp || false,
                isUnfixedExamCorrection: problem.isUnfixedExamCorrection,
                lvl: problem.lvl,
                penalty: problem.penalty || 0,
                skips:
                  problem.skips && problem.skips !== null ? problem.skips : 0,
                status: problem.hasBeenTried
                  ? problem.firstTry
                    ? 'right'
                    : 'wrong'
                  : undefined,
              } as NormalProblem;
            }

            return problemsWithId;
          },
          {}
        );

        set({ problems });

        logRestoreIds('problemStore.setDetails complete (data for squares).');
      },
      setDollars: (dollars: string[]) => set({ dollars }),
      setDollarsOnly: (value: boolean) => {
        if (value === get().dollarsOnly || getIsTest()) {
          set({ dollarsOnly: value });
        } else {
          const dollarSelected = get().dollars.includes(get().selectedID);

          if (!dollarSelected) {
            const { dollars, problems } = get();

            const weakestID = getOrderedIDs({
              dollars,
              orderBy: 'Skill Level',
              problems,
              dollarsOnly: value,
            })[0];

            if (weakestID) {
              normalBoxClick(weakestID);
            }
          }

          set({ dollarsOnly: value });
        }
      },
      setLockedCount: (lockedCount: number) => set({ lockedCount }),
      setOrderBy: (orderBy: OrderOption, updateServer = true) => {
        set({ orderBy });

        if (updateServer) postOrderBy(orderBy);
      },
      setPenalty: (id: string, amount: number) => {
        set(state => {
          const updatedProblems = { ...state.problems };

          if (updatedProblems[id]) {
            updatedProblems[id] = {
              ...updatedProblems[id],
              penalty: amount,
            };
          }

          return { problems: updatedProblems };
        });
      },
      getPenalties: (id: string) => {
        const problem = get().problems[id] as NormalProblem;

        return problem.penalty || 0;
      },
      setSelectedID: (id: string) => {
        set(draft => {
          draft.selectedID = id;
        });

        const problem = getSelectedProblem();

        set({
          currentExamProblemNumber: isExamProblem(problem)
            ? problem.order
            : undefined,
        });
      },
      hasSquare: (id: ID) => !!get().problems[`${id}`],
      resetSquare: (restoreId: string) => {
        const problem = get().problems[restoreId] as NormalProblem;

        if (!problem) return;

        problemState().updateProblem(restoreId, {
          status: 'right',
          penalty: 0,
          skips: 0,
        });
      },
      updateProblem: (restoreId: string, problem: Partial<ProblemShared>) => {
        set(draft => {
          // Possibly launch a leveling up animation modal
          if ('lvl' in problem && restoreId !== draft.levelingUp.id) {
            const current = draft.problems[restoreId] as NormalProblem;
            const currentLevel = colorData[current.lvl].level;
            const latestLevel = colorData[problem.lvl as Proficiency].level;

            if (
              latestLevel > currentLevel &&
              latestLevel > 1 &&
              !draft.animateLevelUp.includes(latestLevel)
            ) {
              draft.previousColor = current.lvl;

              draft.levelingUp = {
                id: restoreId,
                lvl: problem.lvl as Proficiency,
              };

              draft.animateLevelUp.push(latestLevel);

              const latestProblem = omit(problem, 'lvl');

              if (isEmpty(latestProblem)) return;

              Object.assign(draft.problems[restoreId], latestProblem);
            } else {
              Object.assign(draft.problems[restoreId], problem);
            }
          } else {
            Object.assign(draft.problems[restoreId], problem);
          }
        });

        // Additional logic to update other problems with the same skillId
        const target = get().problems[restoreId];

        if (isNormalProblem(target)) {
          set(draft => {
            Object.values(draft.problems).forEach(problem => {
              if (
                isNormalProblem(problem) &&
                problem.skillId === target.skillId
              ) {
                if (problem.id !== restoreId) {
                  problem.lvl = target.lvl;
                  problem.lastFive = target.lastFive;
                  problem.lastTen = target.lastTen;
                  problem.lastThree = target.lastThree;
                  problem.myAllTime = target.myAllTime;
                  problem.daysSince = target.daysSince;
                  problem.lastCorrectDate = target.lastCorrectDate;
                  problem.lastAttempt = target.lastAttempt;
                  problem.rawScore = target.rawScore;
                }
              }
            });
          });
        }
      },
    })),
    { name: 'Problem Store' }
  )
);

export const problemState = (): ProblemStore => problemStore.getState();

export const getNumUnfinished = (): number => {
  const numUnFinished = Object.values(problemState().problems).filter(
    problem => !problem.ready
  ).length;

  return numUnFinished;
};

export const clearAllPenalties = (): void => {
  if (getIsTest()) return;

  const problems = problemState().problems;

  Object.values(problems).forEach((problem: NormalProblem) => {
    problemState().setPenalty(problem.id, 0);
  });
};

export const normalBoxClick = (id: string): void => {
  const curr = problemState().problems[
    problemState().selectedID
  ] as NormalProblem;
  const dollarIDs = problemState().dollars;
  const isDollar = dollarIDs.includes(curr.id);
  const teacherOnline = bannerState().teacherOnline;

  if (isDollar && !isSelected(id)) {
    if (
      curr.type !== 'EXAM_CORRECTIONS' &&
      curr.type !== 'PRACTICE_CORRECTIONS'
    ) {
      if (curr.skips > 3 && teacherOnline) {
        gmmAlert(alerts.notFinished);

        return;
      }
    }

    const totalSkips = curr.skips + 1;

    problemState().updateProblem(curr.id, {
      skips: totalSkips,
    });

    const delta = harvestTimeSeen() || 0;

    if (delta > 1500) {
      sendSkipsUpdate({ id: curr.id, skips: totalSkips, delta });
    } else {
      sendSkipsUpdate({ id: curr.id, skips: totalSkips });
    }
  } else {
    harvestTimeSeen(true);
  }

  setProblem(id);
};

export const examBoxClick = (id: string): void => {
  const problem = problemState().problems[id] as ExamProblem;

  if (problem.locked) return;

  problemState().updateProblem(id, {
    seen: true,
  });

  setProblem(id);

  if (!problem.ready && !problem.notValid) {
    postSawRestoreTest(problem.id);
  }
};

export const getSelectedProblem = (): ProblemShared => {
  const selectedID = problemState().selectedID;
  const selectedProblem = problemState().problems[selectedID];

  return selectedProblem;
};

export const isSelected = (id: string): boolean => {
  const currentProblem = getSelectedProblem();

  return currentProblem ? currentProblem.id === id : false;
};

export function setProblems(data: NewWorkProblems, isTest?: boolean): void {
  logHistory(
    'setProblems started. Will clear, then setDetails, then setProblem'
  );

  problemJsonMap.clear();
  problemState().clear();

  problemState().setDetails(data.problems);

  if (!data.problems) {
    problemState().setNoProblemsOnLogin(true);

    return;
  }

  if (!isTest) {
    if (data.dollarRestoreIds) {
      problemState().setDollars(
        data.dollarRestoreIds.map(dollar => `${dollar}`)
      );
    }

    if (data.selectedRestoreId) {
      if (!setProblem(data.selectedRestoreId)) return;
    }
  } else {
    const problems = problemState().problems as ExamProblems;
    const orderedIDs = getExamOrderedIds(problems);

    // Find the first problem that is clickable and click it to force
    // load of problem state and focus on square
    const found = orderedIDs
      .map(id => problems[id])
      .find((problem: ExamProblem) => !problem.locked && !problem.ready);

    if (found) {
      examBoxClick(found.id);
    } else if (!data.autoUnlockExamsProblems) {
      gmmAlert({
        msg:
          "All the exam problems are locked. You can click 'Request Unlock.'",
        top: 'Locked Out!',
      });
    }
  }

  problemState().setAutoUnlockExamsProblems(!!data.autoUnlockExamsProblems);

  if (data.autoUnlockExamsProblems) {
    unlockAll();
  }

  resetOldest();
}

/**
 * @param id Restore (square) id
 * @param showSmileys Whether to show smileys after forced internal login on setProblem error
 * @returns false if there is no Restore (square) with the given id
 */
export function setProblem(id: ID, showSmileys?: boolean): boolean {
  activity();

  logHistory(`attempting setProblem to Restore ${id}`);

  if (!problemState().hasSquare(id)) {
    sendErrorToServer(`Can't set problem to ${id} cuz problems[${id}] doesn't exist.
      We'll trigger an internal login to refresh Work state. (This message to server can eventually be removed.)`);

    loginInternal({ showSmileys });

    return false;
  }

  studentAppModalState().setLoading(true, 'setProblem');

  const currentProblem = getSelectedProblem();

  // When a student switches to another problem during an exam,
  // auto-submit their work on the problem they were working on
  if (isExamProblem(currentProblem) && currentProblem.uncertain) {
    problemState().updateProblem(currentProblem.id, {
      uncertain: false,
    });
    getGmm()?.submitAllAgs(function () {
      setProblem(id);
    });

    studentAppModalState().setLoading(false, 'setProblem');

    return true;
  }

  problemState().setSelectedID(`${id}`);

  if (!problemJsonMap.has(`${id}`)) {
    logHistory(
      `Cannot immediately complete setProblem because json not loaded yet for Restore ${id}, hitting ServletRestore`
    );
    getGmm()?.setToBlank();
    getProblem(id, getIsTest()!);
    studentAppModalState().setLoading(false, 'setProblem');

    return true;
  }

  if (!isLandscape()) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } else {
    // If there is a div that wraps the ProblemCanvas element, scroll to the top of it
    const scrollableDivs = document.getElementsByClassName(
      'scrollable-problem'
    );

    if (scrollableDivs.length > 0) {
      scrollableDivs[0].scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  // unary plus to convert possible string to number
  getGmm()?.setProblem(problemJsonMap.get(`${id}`)!, +id);

  logHistory(`successfully setProblem to Restore ${id}`);

  resetOldest();

  postRestoreIdSelected(`${id}`);

  studentAppModalState().setLoading(false, 'setProblem');

  return true;
}

export function getMd5(id: ID): string | undefined {
  return problemJsonMap.get(id + '')?.md5;
}

// Someday: oldest is derived from analysis of problems,
// so figure it out in custom hook useProcessedProblemState
export function resetOldest(): void {
  if (getIsTest()) return;
  let oldest = -2;

  if (!getIsTest()) {
    let f = false;

    $.each(problemState().problems as NormalProblems, function (_, val) {
      if (val.daysSince === -1) {
        oldest = -1;
        f = true;
      }

      if (!f) oldest = Math.max(oldest, val.daysSince);
    });
  }

  const result =
    oldest < 0
      ? 'Never'
      : oldest === 0
      ? 'Today'
      : oldest == 1
      ? 'One Day'
      : `${oldest} Days`;

  bannerState().setOldest(result);
}

function getProblem(id: ID, isTest: boolean): void {
  studentAppModalState().setLoading(true, 'getProblem');

  sendRestoreRequest(`${id}`, isTest, {
    onSuccess: problem => receiveProblem({ problem, id }),
    onError: error => {
      getGmm()?.setToBlank();
      studentAppModalState().setLoading(false, 'getProblem');
      gmmAlert(alerts.connectionFailure);

      if (isAxiosError(error)) {
        sendErrorToServer(getAxiosErrorMessage('ServletRestore', error), true);

        return;
      }

      sendErrorToServer(
        `ServletRestore logic fail, error: ${packageGenericError(error)}`,
        true
      );
    },
  });
}
