// A wrapper to provide a context to all components.
// Used to make scrollable div around problem canvas
// available to overlay.tsx so we can scroll problem
// when overlay is showing (such as for message from teacher
// or show answer modal).

import { createContext, useContext, useRef, ReactNode, RefObject } from 'react';

// Define the type of the divRef, which is a ref to an HTMLDivElement or null
type DivRefContextType = RefObject<HTMLDivElement> | null;

// Create a context for the div reference with an initial value of null
const DivRefContext = createContext<DivRefContextType>(null);

// Define the provider's props
interface DivRefProviderProps {
  children: ReactNode;
}

// Create a provider component to wrap the component tree
export function DivRefProvider({ children }: DivRefProviderProps) {
  const divRef = useRef<HTMLDivElement>(null); // Create a ref for the div

  return (
    <DivRefContext.Provider value={divRef}>{children}</DivRefContext.Provider>
  );
}

// Custom hook for easy access to the context
export function useDivRef() {
  const context = useContext(DivRefContext);

  if (!context) {
    throw new Error('useDivRef must be used within a DivRefProvider');
  }

  return context;
}
