import { sendErrorToServer } from '../../studentApp/api';
import { messageCheck } from '../../studentApp/api/messageCheck';
import { bannerState } from '../../studentApp/stores';
import {
  isReloadPending,
  studentAppModalState,
} from '../../studentApp/stores/studentAppModalStore';
import { logHistory, reboot } from '../utils/gmmUtils';

import { inactiveCheck } from './activityMonitor';

let pingPoliceId: NodeJS.Timeout;
let pingId: NodeJS.Timeout;

let lastPingStart: number;
let pingNumber: number;

const MESSAGE_CHECK_INTERVAL_MS = 5000;
const PING_POLICE_INTERVENTION_MS = 600000;
// If more than ten minutes have passed when ping police intervenes, we
// do not report it as an error to server
const MAX_POLICE_ERROR_TIME_MS = 10 * 60 * 1000;

let repeatedPingFails = 0;

export const initializePingPolice = (): void => {
  turnOffPingTimers();
  pingPoliceId = setInterval(pingPolice, MESSAGE_CHECK_INTERVAL_MS);

  lastPingStart = new Date().getTime();

  repeatedPingFails = 0;
  pingNumber = 0;

  nextPing();
};

export function nextPing(): void {
  // in case there is a unsprung ping timer hanging around
  clearTimeout(pingId);
  pingId = setTimeout(ping, MESSAGE_CHECK_INTERVAL_MS);
}

function ping(): void {
  lastPingStart = new Date().getTime();

  if (isReloadPending()) {
    logHistory('Disable pings because a reload of entire app is pending');
    turnOffPingTimers();

    return;
  }

  if (studentAppModalState().loading) {
    logHistory('Skip a ping because loading is true');
    nextPing();

    return;
  }

  const start = new Date().getTime();

  pingNumber++;

  const getTopScores = bannerState().showTopScores;

  logHistory(`ping #${pingNumber}`);

  messageCheck(getTopScores, {
    onPayloadProcessed: () => {
      const timeElapsed = new Date().getTime() - start;

      logHistory(`success on ping #${pingNumber} in ${timeElapsed} ms`);
      repeatedPingFails = 0;
      nextPing();
    },
    onError: () => {
      repeatedPingFails++;

      logHistory(
        `Ping error on ping #${pingNumber}, repeated fails: ${repeatedPingFails}`
      );

      if (repeatedPingFails > 4) {
        reboot(
          "Uh-oh, looks like we can't connect to the server, please try to login again."
        );
        sendErrorToServer(
          'quadruple ping fail, called for reboot to login page',
          true
        );
      } else {
        nextPing();
      }
    },
  });
}

// Sometimes client stops polling (nextPing doesn't resolve), so
// monitor for hanging message check with long-running 'police'
export function pingPolice(): void {
  if (isReloadPending()) {
    logHistory('Disable ping police because a reload is pending');
    turnOffPingTimers();

    return;
  }

  const now = new Date().getTime();

  if (now > lastPingStart + PING_POLICE_INTERVENTION_MS) {
    if (inactiveCheck()) {
      logHistory(
        'Ignoring ping police intervention because of student inactivity'
      );

      return;
    }

    clearTimeout(pingId);

    const timeElapsed = now - lastPingStart;

    logHistory(
      `Ping police detects inert pings, tries to restart pinging. Elapsed time: ${timeElapsed} ms`
    );

    if (timeElapsed < MAX_POLICE_ERROR_TIME_MS) {
      sendErrorToServer(
        `Non-terminal: Ping police activated because lastPingAttempt was ${timeElapsed} ms ago, forcibly calling for next ping`
      );
    }

    ping();
  }
}

export const turnOffPingTimers = (): void => {
  clearTimeout(pingId);
  clearInterval(pingPoliceId);
};
