import { Switch, Tooltip } from '@mui/material';
import { FC } from 'react';

import { useProcessedBannerStore } from '../../../studentApp/hooks';
import { bannerStore } from '../../../studentApp/stores';

export const ShowSquares: FC = () => {
  const [setUserChoiceForShowSquares] = bannerStore(state => [
    state.setUserChoiceForShowSquares,
  ]);
  const { showSquares, themeColor } = useProcessedBannerStore();

  const toggleShowSquares = (): void => {
    setUserChoiceForShowSquares(!showSquares);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Tooltip title={showSquares ? 'Hide squares' : 'Show squares'}>
      <Switch
        checked={showSquares}
        onChange={toggleShowSquares}
        sx={{
          marginRight: 'auto',
          '& .MuiSwitch-thumb': {
            borderRadius: '1px', // square instead of circle
          },
          '& .MuiSwitch-colorPrimary.Mui-checked': {
            color: themeColor,
          },
        }}
      />
    </Tooltip>
  );
};
