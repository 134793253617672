import { Menu, MenuItem, IconButton, Tooltip } from '@mui/material';
import { useState, MouseEvent, FC } from 'react';

import {
  postRequestAssignmentsHistory,
  sendRaisedHand,
} from '../../../studentApp/api';
import { useProcessedWorkStore } from '../../../studentApp/hooks';
import { useLayout } from '../../../studentApp/hooks/useLayout';
import { useProcessedBannerStore } from '../../../studentApp/hooks/useProcessedBannerStore';
import { useProcessedStudentAppModalStore } from '../../../studentApp/hooks/useStudentAppModalStore';
import { bannerStore, problemStore } from '../../../studentApp/stores';
import { getGuid, getSicId } from '../../../studentApp/stores/globalState';
import {
  CHOOSE_WORK,
  EXAMS,
  GAMES,
  INFO,
  ModalType,
  SETTINGS,
  STATISTICS,
  SWITCH_CLASS,
  studentAppModalStore,
} from '../../../studentApp/stores/studentAppModalStore';
import { userStore } from '../../../studentApp/stores/userStore';

import { Hand } from './hand';

export const STUDENT_CALENDAR_READY = 'studentCalendarReady';

export const HamburgerMenu: FC = () => {
  const otherClasses = bannerStore(state => state.otherClasses);
  const setModal = studentAppModalStore(state => state.setCurrentModal);
  const { hasWorkChoices } = useProcessedWorkStore();
  const allowExamsOnMobile = bannerStore(state => state.allowExamsOnMobile);
  const { isMobile } = useLayout();
  const { showExamsMenu } = useProcessedStudentAppModalStore();
  const {
    showGamesMenu,
    showDisabledGamesMenu,
    isShowHand,
  } = useProcessedBannerStore();
  const [isHandRaised, setIsHandRaised] = bannerStore(state => [
    state.isHandRaised,
    state.setIsHandRaised,
  ]);
  const [userName] = userStore(state => [state.userName]);
  const selectedId = problemStore(state => state.selectedID);

  const hideMobileExams = isMobile && !allowExamsOnMobile;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const launchModal = (modal: ModalType) => {
    setModal(modal);
    handleClose();
  };

  const handleAssignmentHistory = (): void => {
    postRequestAssignmentsHistory();
    handleClose();
  };

  const toggleRaiseHand = (): void => {
    sendRaisedHand(!isHandRaised, selectedId);
    setIsHandRaised(!isHandRaised);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Tooltip title="More options">
        <IconButton onClick={handleClick}>
          <img
            src="GMM-icons/GMM-icon-hamburger.svg"
            style={{ height: '30px' }}
          />
        </IconButton>
      </Tooltip>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
      >
        {isMobile && hasWorkChoices && (
          <MenuItem onClick={() => launchModal(CHOOSE_WORK)}>
            Choose Work
          </MenuItem>
        )}

        {isMobile && isShowHand && (
          <MenuItem onClick={toggleRaiseHand}>
            <Hand />
          </MenuItem>
        )}

        {otherClasses.length > 0 && (
          <MenuItem onClick={() => launchModal(SWITCH_CLASS)}>
            Switch Class
          </MenuItem>
        )}

        {showExamsMenu && !hideMobileExams && (
          <MenuItem onClick={() => launchModal(EXAMS)}>
            Exams & Follow-ups
          </MenuItem>
        )}

        {showGamesMenu && (
          <MenuItem onClick={() => launchModal(GAMES)}>Play Game</MenuItem>
        )}

        {showDisabledGamesMenu && <MenuItem disabled>Play Game</MenuItem>}

        <MenuItem onClick={() => launchModal(SETTINGS)}>Settings</MenuItem>

        <MenuItem onClick={() => launchModal(STATISTICS)}>Statistics</MenuItem>

        <MenuItem onClick={() => launchModal(INFO)}>Your Info</MenuItem>

        <MenuItem onClick={() => handleOpenPercentCalendar(userName)}>
          Calendar
        </MenuItem>

        <MenuItem onClick={handleAssignmentHistory}>Work History</MenuItem>

        <MenuItem>
          <a
            href="https://vimeo.com/showcase/7480752"
            rel="noreferrer"
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            App Video Tutorials
          </a>
        </MenuItem>
      </Menu>
    </div>
  );
};

/**
 * We had a student brute force access other student calendars by changing the URL
 * methodically through sicIds, so now we'll stop posting credentials in the URL.
 *
 * Instead, when the calendar tab is opened, it sends a message back to this tab
 * to indicate it is ready to receive the credentials it needs to access the calendar
 * data from the server. Then this tab sends a message back with the credentials.
 *
 * Note: when I just opened the calendar tab and sent it a message immediately from
 * this tab, we had race conditions -- it worked out on Chrome, but not on Safari.
 */
const handleOpenPercentCalendar = (userName: string): void => {
  const win = window.open('studentCalendar.html', '_blank');

  if (!win) return;

  const handleReadyMessage = (event: MessageEvent) => {
    if (event.origin !== window.location.origin) {
      console.warn('Ignored message from untrusted origin:', event.origin);

      return;
    }

    if (event.data === STUDENT_CALENDAR_READY) {
      win.postMessage(
        {
          guid: getGuid(),
          studentInClassId: getSicId(),
          username: userName,
        },
        window.location.origin
      );
      window.removeEventListener('message', handleReadyMessage);
    }
  };

  // Listen for the "ready" message from the calendar tab
  window.addEventListener('message', handleReadyMessage);

  win.focus();
};
