import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { AlertOptions } from '..';

import { axesModalState } from './axesModal';

// Using 'any' to reference the unnamed, untyped Object supplied by
// ProblemCanvasOwner. It's an object built by the 'getText' function
// in that class.
let focused: any;

export const setFocusedNativeInput = (foc: any) => {
  focused = foc;
};

export interface Coordinates {
  x: number;
  y: number;
}

export interface ProblemModalState {
  stackableDialogs: AlertOptions[];
  // double as 'is there a native input box showing?' (undefined --> no)
  nativeInputLocation: Coordinates | undefined;
  nativeInputText: string;
  // Position of cursor on the native input textbox when it mounts.
  // This should reflect the position it was in on the prob auth getter.
  nativeInputCursorOnMount: number;
  permitReadAloud: boolean;
}

export interface ProblemModalStore extends ProblemModalState {
  alert: (dialog: AlertOptions) => void;
  closeAll: () => void;
  popStackableDialog: () => void;
  setNativeInputLocation: (
    nativeInputLocation: Coordinates | undefined
  ) => void;
  setNativeInputText: (text: string) => void;
  setNativeInputCursorOnMount: (cursor: number) => void;
  setPermitReadAloud: (permitReadAloud: boolean) => void;
}

(window as any).__REDUX_DEVTOOLS_EXTENSION__;

export const problemModalStore = create<ProblemModalStore>()(
  devtools(
    immer((set, get) => ({
      stackableDialogs: [],
      nativeInputLocation: undefined,
      nativeInputText: '',
      nativeInputCursorOnMount: 0,
      permitReadAloud: false,
      alert: (dialog: AlertOptions) => {
        let dialogs = get().stackableDialogs;

        if (
          dialogs.length > 0 &&
          dialogs[dialogs.length - 1].removeWhenAnotherDialogShows
        ) {
          dialogs = dialogs.slice(0, -1);
        }

        set({ stackableDialogs: [...dialogs, dialog] });
      },
      closeAll: () => {
        set({ stackableDialogs: [] });
        get().setNativeInputLocation(undefined);
      },
      popStackableDialog: () => {
        const dialogs = get().stackableDialogs;

        if (dialogs.length === 0) return;
        const last = dialogs[dialogs.length - 1];

        if (last.reload) {
          location.reload();
        } else
          set(state => ({
            stackableDialogs: state.stackableDialogs.slice(0, -1),
          }));
      },
      // Set this to undefined to close the native input
      setNativeInputLocation: (
        nativeInputLocation: Coordinates | undefined
      ) => {
        // when closing the native input, rebuild the focused text with native input's contents
        if (get().nativeInputLocation && !nativeInputLocation) {
          focused?.rebuildFromNativeInput(get().nativeInputText);
        }

        set({ nativeInputLocation });
      },
      setNativeInputText: (text: string) => set({ nativeInputText: text }),
      setNativeInputCursorOnMount: (cursorPosition: number) =>
        set({ nativeInputCursorOnMount: cursorPosition }),
      setPermitReadAloud: (permitReadAloud: boolean) =>
        set({ permitReadAloud }),
    })),
    { name: 'Problem Modal Store' }
  )
);

export const problemModalState = (): ProblemModalStore =>
  problemModalStore.getState();

export function closeAllProblemDialogs(): void {
  problemModalState().closeAll();
  axesModalState().setVisible(false);
}
