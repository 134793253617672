import { FC, useEffect, useRef } from 'react';

import { MODAL_Z_INDEX } from '@gmm/problem';

import {
  SHOW_ANSWER,
  studentAppModalStore,
} from '../../../studentApp/stores/studentAppModalStore';

interface OverlayProps {
  close: () => void;
  scrollProblemDespiteOverlay?: boolean;
}

export const Overlay: FC<OverlayProps> = ({
  close,
  scrollProblemDespiteOverlay,
}) => {
  const currentModal = studentAppModalStore(state => state.currentModal);

  const showingAnswer = currentModal === SHOW_ANSWER;
  const transparency = showingAnswer ? 0.2 : 0.4;

  const overlay = useRef<HTMLDivElement>(null);

  // handle React Mouse Event for closing with method closeMe, which should take
  // one parameter for the event
  const closeMe = (event: React.MouseEvent<HTMLDivElement>) => {
    if (showingAnswer) {
      event.stopPropagation();
      event.preventDefault();

      return;
    }

    close();
  };

  // Allow student to scroll problem even when some modals are open:
  // Need help, Show answer, message from teacher
  useEffect(() => {
    if (!scrollProblemDespiteOverlay || !overlay.current) return;

    const forwardScroll = (event: WheelEvent) => {
      const scrollableDiv = document.getElementById(
        'scrollableProblemHolder'
      ) as HTMLDivElement;

      if (scrollableDiv) {
        scrollableDiv.scrollTop += event.deltaY;
      }
    };

    overlay.current.addEventListener('wheel', forwardScroll);

    return () => {
      if (!overlay.current) return;
      overlay.current.removeEventListener('wheel', forwardScroll);
    };
  }, [scrollProblemDespiteOverlay, overlay, overlay.current]);

  return (
    <div
      ref={overlay}
      onClick={closeMe}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: `rgba(0, 0, 0, ${transparency})`,
        zIndex: MODAL_Z_INDEX,
      }}
    />
  );
};
