import { ChooseAssignment } from './postAssignmentChoice';
import { CompleteStudentOnboarding } from './postCompletedOnboarding';
import { RequestTest } from './postExamRequest';
import { GameFinished } from './postGameFinished';
import { HandInExam } from './postHandInExam';
import { IncrementTimeSeen } from './postIncrementTimeSeen';
import { IsBack } from './postIsBack';
import { Log } from './postLog';
import { OrderBy } from './postOrderBy';
import { Override } from './postOverride';
import { Replace } from './postReplace';
import { RequestAssignmentsHistory } from './postRequestAssignmentsHistory';
import { RestoreIdSelected } from './postRestoreIdSelected';
import { GameStopped } from './postSaveGame';
import { SawRestoreTest } from './postSawRestoreTest';
import { SetTheme } from './postSetTheme';
import { Spend } from './postSpendGameCredit';
import { SwitchToSpiralReview } from './postSwitchToSpiralReview';
import { TchrRequestedProb } from './postTchrRequestedProb';
import { ToggleClock } from './postToggleClock';
import { ToggleEffects } from './postToggleEffects';
import { ToggleNativeKeyboard } from './postToggleNativeKeyboard';
import { ToggleStats } from './postToggleStats';
import { ToggleTopScores } from './postToggleTopScores';
import { ToggleForceTouchKeyboard } from './postToggleTouchKeyboard';
import { RequestUnlock } from './postUnlockRequest';

export type SendObjectObj =
  | ChooseAssignment
  | CompleteStudentOnboarding
  | GameStopped
  | GameFinished
  | HandInExam
  | IncrementTimeSeen
  | IsBack
  | Log
  | OrderBy
  | Override
  | Replace
  | RequestAssignmentsHistory
  | RequestTest
  | RequestUnlock
  | RestoreIdSelected
  | SawRestoreTest
  | SetTheme
  | Spend
  | SwitchToSpiralReview
  | TchrRequestedProb
  | ToggleClock
  | ToggleEffects
  | ToggleForceTouchKeyboard
  | ToggleNativeKeyboard
  | ToggleStats
  | ToggleTopScores;

export type SendObjectBase<T> = T & {
  a?: number;
  ss?: number;
  user?: string;
  guid?: string;
};

export type SendObjectType = SendObjectBase<SendObjectObj>;

export const FAULT_TOLERANT_SEND_OBJECT_TYPES = [
  'restoreIdSelected',
  'incrementTimeSeen',
];
