import { render } from 'react-dom';

import { App } from '../onboardingApp/app';
import { Status } from '../onboardingApp/stores/appContext';
import { ThemeProvider } from '../onboardingApp/ui';
import { login, LoginRequest } from '../studentApp/api';
import { configureAxios } from '../studentApp/api/config';
import { SnackbarNotification } from '../studentApp/components/snackbar/snackbarNotification';
import { DevTools } from '../studentApp/devTools';
import { pubsub } from '../studentApp/legacy';
import { turnOffAllTimers } from '../studentApp/utils/gmmUtils';

import { Modals } from './components/modals/modals';
import { StudentApp } from './components/studentApp';

const JWT_TOKEN = 'id_token';

$(window).on('unload', function () {
  turnOffAllTimers();
});

configureAxios();

const urlParams = new URLSearchParams(window.location.search);
// Mutable since the jwt can set this
let autoN = urlParams.get('user') ?? '';
const autoP = urlParams.get('pwd') ?? '';
const autoSubmitNormal = urlParams.has('autoSubmitNormal');
const classCode = urlParams.get('code') ?? '';
const classId = urlParams.get('classId');
const className = urlParams.get('className') ?? '';
const jwt = urlParams.get(JWT_TOKEN);
const status = urlParams.get('status') ?? 'unknown';
const teacherName = urlParams.get('teacherName') ?? '';

// Remove URL params
const newurl =
  window.location.protocol +
  '//' +
  window.location.host +
  window.location.pathname;

window.history.replaceState({ path: newurl }, '', newurl);

const reactApp = document.getElementById('react-app') as HTMLDivElement;
const snackbar = document.getElementById('snackbar') as HTMLDivElement;

const { unsubscribe: unsubscribeFromLogin } = pubsub.subscribe(
  'login',
  (data: LoginRequest): void => {
    const loginData = classId
      ? { ...data, a: Date.now(), classId }
      : { ...data, a: Date.now() };

    login(loginData, false, {
      onSuccess: () => {
        window.sessionStorage.removeItem('appContext');
        unsubscribeFromLogin();

        render(<StudentApp />, reactApp);
      },
    });
  }
);

// Check if a jwt was detected
if (jwt) {
  // Decode the JWT into json
  const jwtJson = parseJwt(jwt);

  if (jwtJson) {
    // Check if we should hide all local auth elements
    if (jwtJson.idp && jwtJson.idp != 'local') {
      // Hide ssoRestricted menu items when logging in via SSO
      Array.from(document.querySelectorAll('.ssoRestricted')).forEach(el => {
        if (!(el instanceof HTMLElement)) return;

        el.style.visibility = 'hidden';
        el.style.height = '0px';
      });
    }

    autoN = jwtJson.preferred_username;
  }
}

render(
  <ThemeProvider>
    <App
      autoLogin={autoSubmitNormal}
      defaultLoginValues={{
        password: autoP,
        username: autoN,
      }}
      pubsub={pubsub}
      urlParams={{
        classCode,
        classId,
        className,
        status: status as Status,
        teacherName,
      }}
    />
    <Modals onboardingApp={true} />
  </ThemeProvider>,
  reactApp
);

render(
  <ThemeProvider>
    <SnackbarNotification />
  </ThemeProvider>,
  snackbar
);

const devTools = document.getElementById('devTools');

render(
  <ThemeProvider>
    <DevTools />
  </ThemeProvider>,
  devTools
);

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function parseJwt(token: string): Record<string, any> {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
