import { AlertOptions, ProblemContext, QueuedSubmit } from '@gmm/problem';

import { alerts, sendErrorToServer } from '../api';
import { attemptSubmitted } from '../api/attemptSubmitted/attemptSubmitted';
import { APIObserver, apiQueue, QueuedAPICall } from '../api/queue';
import { bannerState, problemState } from '../stores';
import { getGmm, getGuid, getIsTest, getUsername } from '../stores/globalState';
import { problemJsonMap } from '../stores/problemJsonMap';
import { getMd5 } from '../stores/problemStore';
import { studentAppModalState } from '../stores/studentAppModalStore';
import { userState } from '../stores/userStore';
import { getThemeColor } from '../types';
import {
  gmmAlert,
  harvestTimeSeen,
  logHistory,
  processAjaxFailure,
} from '../utils/gmmUtils';
import { isNormalProblem } from '../utils/problemUtils';

import { activity } from './activityMonitor';

function isUseNativeKeyboard(): boolean | undefined {
  return bannerState().useNativeKeyboard;
}

function isForceTouchKeyboard(): boolean | undefined {
  return bannerState().touchKeyboard;
}

function setCurrentUncertain(): void {
  const problems = problemState().problems;
  const selectedID = problemState().selectedID;

  if (!problems[selectedID]) {
    const msg =
      '[setCurrentUncertain] No box for current problem id: ' + selectedID;

    sendErrorToServer(msg);

    return;
  }

  problemState().updateProblem(selectedID, {
    uncertain: true,
  });
}

const errorToServer = (msg: string): void => {
  sendErrorToServer(msg);
};

const isStudent = (): boolean => true;

const getSS = (): number => userState().redisSessionId;

const isPermitReadAloud = (): boolean => bannerState().permitReadAloud;
const setLoading = (loading: boolean): void =>
  studentAppModalState().setLoading(loading, 'Submit');

const addStackableDialog = (dialog: AlertOptions): void => {
  dialog.problemRelated = true;
  studentAppModalState().addStackableDialog(dialog);
};

const getColor = (): string => {
  const themeOption = bannerState().themeOption;
  const themeColor = getThemeColor(themeOption);

  return themeColor.replace('rgb', 'rgba').replace(')', ', 0.75)');
};

const getCurrentSkillId = (): number | undefined => {
  const problems = problemState().problems;
  const selectedID = problemState().selectedID;

  if (!isNormalProblem(problems[selectedID])) {
    return undefined;
  }

  // @ts-ignore Resolve
  const skillId = problems[selectedID].skillId;

  return skillId;
};

const addQueuedSubmit = (queuedSubmit: QueuedSubmit): void => {
  const apiCall = (observer: APIObserver) => {
    try {
      queuedSubmit.submit();
    } finally {
      observer.done();
    }
  };

  const queuedAPICall: QueuedAPICall = {
    apiCall,
    isInvalid: (): boolean => {
      const probState = problemState();

      if (!probState.hasSquare(queuedSubmit.id)) {
        logHistory(`Abandon submit: no square for id ${queuedSubmit.id}`);

        return true;
      }

      if (getMd5(queuedSubmit.id) !== queuedSubmit.md5) {
        logHistory(`Abandon submit: md5 mismatch for id ${queuedSubmit.id}
            Expected: ${getMd5(queuedSubmit.id)}, got: ${queuedSubmit.md5}`);

        return true;
      }

      return false;
    },
    onCancellation: () => {
      if (!studentAppModalState().hasDialog()) {
        gmmAlert(alerts.submitCanceled);
      }

      studentAppModalState().setLoading(false, 'Submit');
      getGmm().setSubmitLock(false);
    },
    description: `Submit ${queuedSubmit.id}`,
  };

  apiQueue.add(queuedAPICall);
};

// Provides the Student App as the implementation of ProblemContext for package @gmm/problem.
export const problemContext: ProblemContext = {
  activity,
  addQueuedSubmit,
  attemptSubmitted,
  errorToServer,
  getGuid: (): string => getGuid(),
  getMd5,
  getProblems: (): any => problemJsonMap,
  getSS,
  getUsername: (): string => getUsername(),
  harvestTimeSeen,
  isForceTouchKeyboard,
  isPermitReadAloud,
  isStudent,
  isTesting: (): boolean => getIsTest(),
  isUseNativeKeyboard,
  logHistory,
  processAjaxFailure,
  setCurrentUncertain,
  setLoading,
  addStackableDialog,
  getThemeColor: getColor,
  getCurrentSkillId,
};
