import axios, { AxiosInstance } from 'axios';

import { convertStringToBool } from './utils';

export const configureAxios = (): void => {
  configureAxiosInstance(axios);
};

export const configureAxiosInstance = (instance: AxiosInstance): void => {
  instance.defaults.baseURL = process.env.JAVA_URL;
  instance.defaults.headers.post['Content-Type'] =
    'application/x-www-form-urlencoded';

  instance.interceptors.response.use(function axiosInterceptorResponseSuccess(
    response
  ) {
    if (response.data) {
      response.data = convertStringToBool(response.data);
    }

    return response;
  });
};
