import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { AlertOptions, isTouchDevice, ProblemData } from '@gmm/problem';

import { FinishedWorkOptions } from '../../cnusr/components/modals/finishedWorkDialog';
import { NewExamDialogOptions } from '../../cnusr/components/modals/newExamDialog';
import {
  Game,
  NormalizedExamDatum,
  ReceiveProblem,
} from '../api/responseHandlerShared';
import { Assignment } from '../types';
import { logHistory, loginInternal } from '../utils';

export type LoadingType =
  | 'login'
  | 'loginReact'
  | 'sendRaisedHand'
  | 'setProblem'
  | 'getProblem'
  | 'launchGame'
  | 'ServletRestore'
  | 'Submit'
  | 'SendObject'
  // Disregard any existing loading state, turn off loading
  | 'nuclear';

export type ModalType =
  | 'none'
  | 'settings'
  | 'statistics'
  | 'exams'
  | 'games'
  | 'work'
  | 'info'
  | 'examWarning'
  | 'chooseWork'
  | 'workHistory'
  | 'switchClass'
  | 'needHelp'
  | 'showAnswer'
  | 'welcome'
  | 'smileys'
  | 'loading'
  | 'game';
export const SETTINGS: ModalType = 'settings';
export const STATISTICS: ModalType = 'statistics';
export const EXAM_WARNING: ModalType = 'examWarning';
export const EXAMS: ModalType = 'exams';
export const INFO: ModalType = 'info';
export const GAMES: ModalType = 'games';
export const CHOOSE_WORK: ModalType = 'work';
export const WORK_HISTORY: ModalType = 'workHistory';
export const SWITCH_CLASS: ModalType = 'switchClass';
export const NEED_HELP: ModalType = 'needHelp';
export const SHOW_ANSWER: ModalType = 'showAnswer';
export const WELCOME: ModalType = 'welcome';
export const SMILEYS: ModalType = 'smileys';
export const LOADING: ModalType = 'loading';
export const GAME: ModalType = 'game';
export const NO_MODAL: ModalType = 'none';

export interface StudentAppModalStoreState {
  currentModal: ModalType;
  stackableDialogs: AlertOptions[];
  stackableMessagesFromTeacher: string[];
  // undefined means not showing
  newExamDialog: NewExamDialogOptions | undefined;
  finishedWork: FinishedWorkOptions | undefined;

  examHandInText: string;

  // full screen, locked out of app
  loading: boolean;
  // lightweight, just a graphic, app still mouse/key responsive
  examLoading: boolean;
  // event(s) that triggered loading
  loadingEvents: Set<LoadingType>;

  topScores: string[];
  // minutes since last correct, displays on optional clock
  timeStuck: number;

  // used during need help, show answer
  answerToCurrentProblem: ProblemData | undefined;
  replacementProblem: ReceiveProblem | undefined;

  availableWork: Assignment[];
  workHistory: Assignment[];
  availableExams: NormalizedExamDatum[];
  availableGames: Game[];

  touchDevice: boolean;
}

const defaults: StudentAppModalStoreState = {
  currentModal: NO_MODAL,
  stackableDialogs: [],
  stackableMessagesFromTeacher: [],
  newExamDialog: undefined,
  finishedWork: undefined,
  examHandInText: 'Are you sure?',
  loading: false,
  examLoading: false,
  loadingEvents: new Set(),
  topScores: [],
  timeStuck: 0,
  replacementProblem: undefined,
  answerToCurrentProblem: undefined,
  availableWork: [],
  workHistory: [],
  availableExams: [],
  availableGames: [],
  touchDevice: isTouchDevice(),
};

export interface StudentAppModalStore extends StudentAppModalStoreState {
  setCurrentModal: (currentModal: ModalType) => void;
  addStackableDialog: (dialog: AlertOptions) => void;
  popStackableDialog: () => void;
  showExamHandInWarning: (examHandInText: string) => void;
  addStackableMessageFromTeacher: (message: string) => void;
  popStackableMessageFromTeacher: () => void;
  setNewExamDialog: (newExamDialog: NewExamDialogOptions | undefined) => void;
  setFinishedWork: (finishedWork: FinishedWorkOptions | undefined) => void;
  setLoading: (loading: boolean, eventSource: LoadingType) => void;
  isLoading: () => boolean;
  stopSmileys: () => void;
  setExamLoading: (examLoading: boolean) => void;
  closeAll: () => void;
  setTopScores: (topScores: string[]) => void;
  setTimeStuck: (timeStuck: number) => void;
  setReplacementProblem: (
    replacementProblem: ReceiveProblem | undefined
  ) => void;
  setAnswerToCurrentProblem: (
    answerToCurrentProblem: ProblemData | undefined
  ) => void;
  setAvailableWork: (availableWork: Assignment[]) => void;
  setWorkHistory: (workHistory: Assignment[]) => void;
  setAvailableExams: (exams: NormalizedExamDatum[]) => void;
  addExams: (exams: NormalizedExamDatum[]) => void;
  removeExams: (exams: NormalizedExamDatum[]) => void;
  setAvailableGames: (games: Game[]) => void;
  clear: () => void;
  hasDialog(): boolean;
  closeAllProblemDialogs(): void;
}

(window as any).__REDUX_DEVTOOLS_EXTENSION__;

export const studentAppModalStore = create<StudentAppModalStore>()(
  devtools(
    immer((set, get) => ({
      currentModal: NO_MODAL,
      stackableDialogs: [],
      examHandInText: 'Are you sure?',
      stackableMessagesFromTeacher: [],
      newExamDialog: undefined,
      finishedWork: undefined,
      loading: false,
      examLoading: false,
      loadingEvents: new Set(),
      topScores: [],
      timeStuck: 0,
      replacementProblem: undefined,
      answerToCurrentProblem: undefined,
      availableWork: [],
      workHistory: [],
      availableExams: [],
      availableGames: [],
      touchDevice: isTouchDevice(),
      clear: () => set(defaults),
      setTimeStuck: (timeStuck: number) => set({ timeStuck }),
      setTopScores: (topScores: string[]) => set({ topScores }),
      setCurrentModal: (currentModal: ModalType) => set({ currentModal }),
      addStackableDialog: (dialog: AlertOptions) => {
        let dialogs = get().stackableDialogs;

        if (
          dialogs.length > 0 &&
          dialogs[dialogs.length - 1].removeWhenAnotherDialogShows
        ) {
          dialogs = dialogs.slice(0, -1);
        }

        if (dialog.killDuplicates) {
          dialogs = dialogs.filter(d => d.msg !== dialog.msg);
        }

        set({ stackableDialogs: [...dialogs, dialog] });
      },
      popStackableDialog: () => {
        const dialogs = get().stackableDialogs;
        const last = dialogs[dialogs.length - 1];

        if (last.reload) {
          if (!last.reloadIsInternal) {
            location.reload();

            return;
          }

          loginInternal();
        } else
          set(state => ({
            stackableDialogs: state.stackableDialogs.slice(0, -1),
          }));
      },
      closeAllProblemDialogs: () => {
        const dialogs = get().stackableDialogs;
        // filter out dialogs with "Invalid" in top
        const filtered = dialogs.filter(dialog => !dialog.problemRelated);

        set({ stackableDialogs: filtered });

        if (get().currentModal === NEED_HELP) {
          set({ currentModal: NO_MODAL });
        }
      },
      hasDialog: () => get().stackableDialogs.length > 0,
      setNewExamDialog: (newExamDialog: NewExamDialogOptions | undefined) =>
        set({ newExamDialog }),
      setFinishedWork: (finishedWork: FinishedWorkOptions | undefined) =>
        set({ finishedWork }),
      showExamHandInWarning: (examHandInText: string) => {
        set({ examHandInText });
        set({ currentModal: EXAM_WARNING });
      },
      addStackableMessageFromTeacher: (message: string) =>
        set(state => ({
          stackableMessagesFromTeacher: [
            ...state.stackableMessagesFromTeacher,
            message,
          ],
        })),
      popStackableMessageFromTeacher: () =>
        set(state => ({
          stackableMessagesFromTeacher: state.stackableMessagesFromTeacher.slice(
            0,
            -1
          ),
        })),
      stopSmileys: () => {
        const current = get().currentModal;

        if (current === SMILEYS) set({ currentModal: NO_MODAL });
      },
      setLoading: (requestLoading: boolean, eventSource: LoadingType) => {
        if (eventSource === 'nuclear') {
          set({ loadingEvents: new Set(), loading: false });
          logHistory('NUCLEAR changed loading to false');

          return;
        }

        const { loadingEvents, loading } = get();
        const updatedLoadingEvents = new Set(loadingEvents);

        requestLoading
          ? updatedLoadingEvents.add(eventSource)
          : updatedLoadingEvents.delete(eventSource);

        const hasActiveEvents = updatedLoadingEvents.size > 0;

        set({ loadingEvents: updatedLoadingEvents, loading: hasActiveEvents });

        if (hasActiveEvents !== loading) {
          logHistory(
            `Changed loading to ${requestLoading}, eventSource: ${eventSource}`
          );
        }
      },

      isLoading: () => get().currentModal === LOADING,
      setExamLoading: (examLoading: boolean) => set({ examLoading }),
      closeAll: () =>
        set({
          currentModal: NO_MODAL,
          stackableDialogs: [],
          stackableMessagesFromTeacher: [],
          newExamDialog: undefined,
          finishedWork: undefined,
        }),
      setReplacementProblem: (replacementProblem: ReceiveProblem | undefined) =>
        set({ replacementProblem }),
      setAnswerToCurrentProblem: (
        answerToCurrentProblem: ProblemData | undefined
      ) => set({ answerToCurrentProblem }),
      setAvailableWork: (availableWork: Assignment[]) => set({ availableWork }),
      setWorkHistory: (workHistory: Assignment[]) => set({ workHistory }),
      setAvailableExams: (exams: NormalizedExamDatum[]) =>
        set({ availableExams: exams }),
      addExams: (potentialAddedExams: NormalizedExamDatum[]) => {
        // Following ugly old pattern, we remove any existing exams with matching names.
        // This is how we remove an old follow-up exam if a new one comes in, since new
        // ones won't have the same ids.
        const currentExams = get().availableExams;
        const potentialAddedExamNames = potentialAddedExams.map(
          e => e.testName
        );
        const removedMatchingNames = currentExams.filter(
          exam => !potentialAddedExamNames.includes(exam.testName)
        );
        // Parroting old jquery logic: "only add if it does not already exist in the DOM"
        const addThese = potentialAddedExams.filter(
          exam =>
            !removedMatchingNames.some(
              e => e.studentInTestId === exam.studentInTestId
            )
        );

        set({ availableExams: addThese.concat(removedMatchingNames) });
      },
      removeExams: (removals: NormalizedExamDatum[]) => {
        const state = get();

        const removedIdMatches = state.availableExams.filter(
          exam =>
            !removals.find(
              e =>
                !!e.studentInTestId &&
                e.studentInTestId === exam.studentInTestId
            )
        );

        const updates: Partial<StudentAppModalStoreState> = {
          availableExams: removedIdMatches,
        };

        if (state.currentModal === EXAMS && removedIdMatches.length === 0) {
          updates.currentModal = NO_MODAL;
        }

        set(updates);
      },
      setAvailableGames: (games: Game[]) => {
        set({ availableGames: games });

        if (get().currentModal === GAMES && games.length === 0) {
          set({ currentModal: NO_MODAL });
        }
      },
    })),
    { name: 'Student App Modal Store' }
  )
);

export const studentAppModalState = (): StudentAppModalStore =>
  studentAppModalStore.getState();

// Is there a modal showing that, when closed, will forcibly reload the page?
export const isReloadPending = (): boolean => {
  const dialogs = studentAppModalState().stackableDialogs;

  return dialogs.some(dialog => dialog.reload);
};
