import {
  getIsTest,
  getTimeStuck,
  setTimeStuck,
} from '../../studentApp/stores/globalState';
import { studentAppModalState } from '../../studentApp/stores/studentAppModalStore';
import { gmmAlert } from '../utils';

export const INACTIVE_MAX = 45 * 60 * 1000;
const INACTIVE_CHECK_INTERVAL = 5000;

let inactiveTimer: NodeJS.Timeout;

let lastActivity = new Date().getTime();
let lastTime = lastActivity;
let lastTimeStuckCheck = lastActivity;

export function resetActivityMonitor(): void {
  turnOffInactiveTimer();
  inactiveTimer = setInterval(inactiveCheck, INACTIVE_CHECK_INTERVAL);

  lastActivity = new Date().getTime();
  lastTime = lastActivity;
  lastTimeStuckCheck = lastActivity;
}

export function activity(): boolean {
  lastActivity = new Date().getTime();

  if (lastTime && lastActivity - lastTime > INACTIVE_MAX) {
    gmmAlert({
      msg: 'You were logged off due to inactivity!',
      reload: true,
      top: "You've been logged out",
    });

    return false;
  }

  lastTime = lastActivity;

  return true;
}

export function inactiveCheck(): boolean {
  const now = new Date().getTime();

  if (!getIsTest()) {
    const oldMinutes = getTimeStuckInMin();
    const delta = now - lastTimeStuckCheck;

    setTimeStuck(getTimeStuck() ?? 0);

    setTimeStuck(getTimeStuck()! + delta);
    const newMinutes = getTimeStuckInMin();

    if (newMinutes != oldMinutes) {
      updateTimeStuckDisplay();
    }

    lastTimeStuckCheck = now;
  }

  if (now - lastActivity > INACTIVE_MAX) {
    gmmAlert({
      msg: 'You were logged off due to inactivity.',
      reload: true,
      top: "You've been logged out",
    });

    return true;
  }

  return false;
}

function getTimeStuckInMin(): number {
  const timeStuck = getTimeStuck();

  if (!timeStuck) return 0;
  if (timeStuck < 0) return 0;

  return Math.floor(timeStuck / 60000);
}

export function updateTimeStuckDisplay(): void {
  const minutes = getTimeStuckInMin();

  studentAppModalState().setTimeStuck(minutes);
}

export function turnOffInactiveTimer(): void {
  clearInterval(inactiveTimer);
}
