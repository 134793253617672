import { unstable_batchedUpdates } from 'react-dom';

import { ProblemData, decodeProblemData } from '@gmm/problem';

import { NEW_EXAM_AVAILABLE_MESSAGE } from '../../cnusr/components/modals/newExamDialog';
import { activity } from '../legacy/activityMonitor';
import gameMaster from '../legacy/gameengine';
import { bannerState } from '../stores/bannerStore';
import { getGmm, getIsTest } from '../stores/globalState';
import { problemJsonMap } from '../stores/problemJsonMap';
import {
  examBoxClick,
  getSelectedProblem,
  isSelected,
  problemState,
  setProblem,
} from '../stores/problemStore';
import { studentAppModalState } from '../stores/studentAppModalStore';
import { workState } from '../stores/workStore';
import { Assignment, ExamDatum, ExamProblems, ID } from '../types';
import { gmmAlert } from '../utils';
import {
  harvestTimeSeen,
  isMobile,
  logHistory,
  processAjaxFailure,
} from '../utils/gmmUtils';

import {
  postSetTheme,
  sendErrorToServer,
  SwitchToSpiralReviewSettings,
} from '.';

// server guarantees String a/b where a <= b, both natural numbers
export function updateAllTime(allTimeEligibleOnly: string): void {
  const i = allTimeEligibleOnly.indexOf('/');
  const correct = parseInt(allTimeEligibleOnly.substring(0, i));
  const attempts = parseInt(allTimeEligibleOnly.substring(i + 1));

  bannerState().setPointsEarned(correct);
  bannerState().setTotalDollarAttempts(attempts);
}

export function themeUpdated(): void {
  const themeOption = bannerState().themeOption;

  postSetTheme(themeOption);

  getGmm()?.repaint();
}

interface ProcessExamsOptions {
  isAdd?: boolean;
  testData?: ExamDatum[];
}

export function updateExamsList(options: ProcessExamsOptions): void {
  if (!options) return;
  const testData = normalizeExamData(options.testData);

  if (options.isAdd) studentAppModalState().addExams(testData);
  else studentAppModalState().removeExams(testData);
}

export function updateSwitchToSpiralReviewSettings(
  switchToSpiralReviewSettings: SwitchToSpiralReviewSettings
): void {
  if (!switchToSpiralReviewSettings) return;

  const requireStudentsToFinishDefaultAssignment = workState()
    .requireStudentsToFinishDefaultAssignment;
  const isClasDefaultWorkSpiralReview = workState()
    .isClasDefaultWorkSpiralReview;

  if (
    requireStudentsToFinishDefaultAssignment ===
      switchToSpiralReviewSettings.requireStudentsToFinishDefaultAssignment &&
    isClasDefaultWorkSpiralReview ===
      switchToSpiralReviewSettings.isClasDefaultWorkSpiralReview
  ) {
    return;
  }

  if (
    switchToSpiralReviewSettings.requireStudentsToFinishDefaultAssignment !==
    undefined
  ) {
    workState().setRequireStudentsToFinishDefaultAssignment(
      switchToSpiralReviewSettings.requireStudentsToFinishDefaultAssignment
    );
  }

  if (
    switchToSpiralReviewSettings.isClasDefaultWorkSpiralReview !== undefined
  ) {
    workState().setIsClasDefaultWorkSpiralReview(
      switchToSpiralReviewSettings.isClasDefaultWorkSpiralReview
    );
  }
}

export function processAvailableWork(work?: Assignment[]): void {
  if (!work) return;
  studentAppModalState().setAvailableWork(work);

  const defaultWork = work.find(assignment => assignment.defaultAssignment);
  const currentWork = workState().currentWork;

  // Update the defaultAssignment property of currentWork...
  // ...needs to be updated so we know if students should
  // be able to choose other work (based on teacher setting)
  currentWork.defaultAssignment =
    defaultWork && currentWork.workId === defaultWork.workId;
}

export interface NormalizedExamDatum {
  studentInTestId: number;
  testId?: number;
  testName: string;
  points?: number;
  total?: number;
  isFollowUp?: boolean;
}

export function normalizeExamData(
  testData?: ExamDatum[]
): NormalizedExamDatum[] {
  if (!testData) return [];

  return testData.map(examDatum => {
    return {
      testName: examDatum.name || 'Unnamed Exam',
      studentInTestId: examDatum.sitId || -1,
      testId: examDatum.ticId || -1,
      isFollowUp: examDatum.isFollowUp || false,
    };
  });
}

export interface Game {
  human: string;
  img: string;
  internal: string;
}

export function updateGameCredits(
  value: number,
  pointsTowardGameCredit?: number,
  pointsForGameCredit?: number
): void {
  bannerState().setGameCredits(value);
  if (pointsTowardGameCredit !== undefined)
    bannerState().setPointsTowardGameCredit(pointsTowardGameCredit);
  if (pointsForGameCredit !== undefined)
    bannerState().setPointsForGameCredit(pointsForGameCredit);
}

export function unlockAll(): void {
  if (!getIsTest()) return;

  const problems = problemState().problems as ExamProblems;
  const selected = getSelectedProblem();

  Object.keys(problems).forEach(key => {
    problemState().updateProblem(key, {
      locked: false,
    });
  });

  const next = Object.values(problems).find(problem => !problem.ready);

  problemState().setLockedCount(0);

  if (!selected) examBoxClick(next ? next.id : Object.keys(problems)[0]);
}

export interface ReceiveProblem {
  id: ID;
  performingTargetedOverride?: boolean;
  problem: ProblemData;
}

export function receiveProblem({
  id,
  performingTargetedOverride,
  problem,
}: ReceiveProblem): void {
  unstable_batchedUpdates(() => {
    try {
      const problems = problemState().problems;

      // when performing targetedOverride, ensure overridden problem is still part of
      // overall problem tableau -- don't update if skill is gone!
      if (performingTargetedOverride) {
        if (!problems[`${id}`]) {
          logHistory(
            `ignoring targeted override in receiveProblem, as Restore ${id} is no longer present in colored squares.`
          );

          return;
        }

        logHistory(
          'performing targeted override in receiveProblem for restore ' + id
        );
      }

      decodeProblemData(problem);

      problemJsonMap.set(`${id}`, problem);
      logHistory(
        `Received problem json for Restore ${id}, including md5 ${problem.md5}`
      );

      // when performing targeted override, only set selected problem
      // if overridden problem is current problem (moves focus)
      if (!performingTargetedOverride || isSelected(`${id}`)) {
        setProblem(id);
      }
    } finally {
      studentAppModalState().setLoading(false, 'getProblem');
    }
  });
}

export function notifyNewExam(sitId: string | number): void {
  if (getIsTest()) return;

  if (isMobile() && !bannerState().allowExamsOnMobile) return;

  studentAppModalState().setNewExamDialog({
    message: NEW_EXAM_AVAILABLE_MESSAGE,
    // unary plus converts string to number, or leaves number alone
    sitId: +sitId,
  });

  return;
}

export interface LaunchGame {
  game?: string;
  gameDenied?: boolean;
  gameCredits?: number;
  saved?: string;
  time?: string;
}

export function launchGame(data: LaunchGame): void {
  if (!activity()) return;

  studentAppModalState().setLoading(true, 'launchGame');

  if (data.gameDenied) {
    studentAppModalState().setLoading(false, 'launchGame');
    gmmAlert({
      msg: 'Game launch denied',
      top: 'GMM',
    });

    return;
  }

  harvestTimeSeen(true);

  if (typeof data.gameCredits === 'number') updateGameCredits(data.gameCredits);

  if (!gameMaster.hasGame(data.game!)) {
    const url = `/games/${data.game}.js`;

    $.ajax({
      url: url,
      dataType: 'script',
      success: function launchGameSuccess() {
        launchGame(data);
      },
      error: function launchGameError(jqXHR) {
        studentAppModalState().setLoading(false, 'launchGame');

        // if this error could be processed, stop here.
        if (processAjaxFailure(jqXHR)) return;

        sendErrorToServer('game launch ajax failed');
      },
    });

    return;
  }

  const game = gameMaster.getGame(data.game!);

  gameMaster.setGame(game, parseInt(data.time!), data.saved!);

  studentAppModalState().setLoading(false, 'launchGame');
}

export function updateDollarProgress(
  pointsTowardGameCredit?: number,
  pointsForGameCredit?: number
): void {
  if (pointsTowardGameCredit !== undefined)
    bannerState().setPointsTowardGameCredit(pointsTowardGameCredit);
  if (pointsForGameCredit !== undefined)
    bannerState().setPointsForGameCredit(pointsForGameCredit);
}

export function updateReplacementsPerDay(
  replacementsPerDay: number | undefined
): void {
  if (replacementsPerDay === undefined) return;

  bannerState().setReplacementsPerDay(replacementsPerDay);
}

export function updatePermitReadAloud(
  permitReadAloud: boolean | undefined
): void {
  if (permitReadAloud === undefined) return;

  bannerState().setPermitReadAloud(permitReadAloud);
}
