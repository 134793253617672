import { FC } from 'react';
import Draggable from 'react-draggable';

import { ModalHeader, modalStyle } from '@gmm/problem';

import {
  postRequestAssignmentsHistory,
  postSwitchToSpiralReview,
} from '../../../../studentApp/api';
import { useProcessedWorkStore } from '../../../../studentApp/hooks';

import { ListItem } from './listItem';
import { buildWorkItem } from './workHistory';

interface AvailableWorkProps {
  close: () => void;
}

export const AvailableWork: FC<AvailableWorkProps> = ({ close }) => {
  const { currentWorkType, trulyAvailableWork } = useProcessedWorkStore();

  return (
    <Draggable handle=".gmm-available-work-modal">
      <div className="blue" style={modalStyle}>
        <ModalHeader
          title="Available Work"
          close={close}
          dragHandle={'gmm-available-work-modal'}
        />

        <div style={{ height: '15px' }}></div>

        <div className="limit-list-height">
          {currentWorkType !== 'SPIRAL_REVIEW' && (
            <ListItem
              key={-1}
              text="Spiral Review"
              color="green"
              launch={() => launchSpiralReview(close)}
            />
          )}

          {trulyAvailableWork.map(work => buildWorkItem(work, close))}
        </div>

        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span
            className="view-work-history"
            onClick={postRequestAssignmentsHistory}
          >
            View Work History
          </span>
        </div>
      </div>
    </Draggable>
  );
};

const launchSpiralReview = (close: () => void) => {
  postSwitchToSpiralReview();
  close();
};
