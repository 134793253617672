import { makeStyles } from '@material-ui/core';
import { ArrowDropDown } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { CSSProperties, FC } from 'react';

import {
  useLayout,
  useProcessedBannerStore,
  useProcessedWorkStore,
} from '../../../studentApp/hooks';
import { getType } from '../../../studentApp/hooks/useProcessedWorkStore';
import {
  CHOOSE_WORK,
  studentAppModalStore,
} from '../../../studentApp/stores/studentAppModalStore';
import { workStore } from '../../../studentApp/stores/workStore';
import { Exam } from '../../../studentApp/types/types';
import {
  BANNER_HEIGHT,
  MAX_LANDSCAPE_BANNER_WORK_NAME_WIDTH,
  MAX_PORTRAIT_BANNER_WORK_NAME_WIDTH,
} from '../../constants';
import { TextOnCanvas } from '../util/textOnCanvas';

const useStyles = makeStyles({
  workName: (props: StyleProps) => ({
    fontSize: '18px',
    fontWeight: 800,
    color: 'white',
    maxWidth: props.maxWidth,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
});

const SPIRAL = 'GMM-icons/GMM-icon-transparent-spiral.svg';

export const WorkNameAndProgress: FC = () => {
  const {
    currentWorkName,
    currentWorkProgress,
    currentWorkType,
  } = useProcessedWorkStore();
  const isLandScape = useLayout().isLandscape;

  const classes = useStyles({
    maxWidth: isLandScape
      ? MAX_LANDSCAPE_BANNER_WORK_NAME_WIDTH
      : MAX_PORTRAIT_BANNER_WORK_NAME_WIDTH,
  });

  const [work] = workStore(state => [state.currentWork]);
  const isFollowUp = getType(work) === 'EXAM' && (work as Exam).isFollowUp;
  const { themeColor } = useProcessedBannerStore();

  return (
    <div style={middleStyle}>
      {currentWorkType === 'EXAM' && (
        <span className={classes.workName}>
          {isFollowUp
            ? currentWorkName.split(' (Follow-up)')[0]
            : currentWorkName}
        </span>
      )}
      {currentWorkType === 'EXAM' && isFollowUp && (
        <span
          className={classes.workName}
          style={{
            color: themeColor,
            backgroundColor: 'white',
            borderRadius: '10px',
            marginLeft: '5px',
            padding: '5px',
          }}
        >
          Follow-up
        </span>
      )}

      {currentWorkType === 'SPIRAL_REVIEW' && (
        <img
          src={SPIRAL}
          height={BANNER_HEIGHT - 20}
          style={{ marginRight: '5px' }}
        />
      )}

      {currentWorkType !== 'EXAM' && <WorkChooser />}

      {currentWorkType !== 'EXAM' && currentWorkType !== 'SPIRAL_REVIEW' && (
        <TextOnCanvas
          text={currentWorkProgress}
          bold={true}
          fontSize={20}
          color="white"
          id="workProgress"
        />
      )}
    </div>
  );
};

const middleStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: 'auto',
};

interface StyleProps {
  maxWidth: number;
}

export const WorkChooser: FC = () => {
  const setModal = studentAppModalStore(state => state.setCurrentModal);
  const workName = useProcessedWorkStore().currentWorkName;
  const { isLandscape } = useLayout();
  const { hasWorkChoices } = useProcessedWorkStore();

  const classes = useStyles({
    maxWidth: isLandscape
      ? MAX_LANDSCAPE_BANNER_WORK_NAME_WIDTH
      : MAX_PORTRAIT_BANNER_WORK_NAME_WIDTH,
  });

  return (
    <>
      {!hasWorkChoices && (
        <span className={classes.workName} style={{ paddingRight: '15px' }}>
          {workName}
        </span>
      )}

      {hasWorkChoices && (
        <Tooltip title="Change work">
          <div
            onClick={() => setModal(CHOOSE_WORK)}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              marginRight: '15px',
            }}
          >
            <span className={classes.workName}>{workName}</span>
            <ArrowDropDown sx={{ color: 'white', fontSize: 28 }} />
          </div>
        </Tooltip>
      )}
    </>
  );
};
