import { AxiosError } from 'axios';
import { isObject } from 'lodash';

import { logHistory, reboot } from '../utils/gmmUtils';

// Student Settings, must be converted back to string bool before going up to server
// This is handled in the sendObject for their particular types.
export const boolToString = [
  'effects',
  'forceTouchKeyboard',
  'nativeKeyboard',
  'showTopScores',
  'showClock',
];

const submitStringBools = ['deckedPen', 'inkedPen', 'uF', 'inv', 'blanked'];

export const stringToBool = [
  'badCredentials',
  'bg',
  'bgclas',
  'clasOff',
  'noClas',
  'tO',
  ...boolToString,
  ...submitStringBools,
];

export function convertStringToBool<T extends Record<string, any>>(data: T): T {
  Object.keys(data).forEach(key => {
    // We never want to touch the `p` because it's parsed by ProblemJS
    if (key === 'p') return;

    const value = data[key];

    if (value === null || value === undefined) return;

    if (isObject(value)) {
      convertStringToBool(value);
    } else if (value === 't' || value === 'f') {
      (data as Record<string, any>)[key] = value === 't';
    }
  });

  return data;
}

export const handleSessionClosed = (): void => {
  reboot('Session closed -- did you log in somewhere else?');

  return;
};

export const getAxiosErrorMessage = (
  pre: string,
  error: AxiosError
): string => {
  const statusCode = error.response?.status || 'none';
  const errorMessage = error.message || 'unknown';
  const responseData = error.response?.data || 'none';

  return `${pre} Axios error
    status code: ${statusCode}
    error message: ${errorMessage}
    response data: ${JSON.stringify(responseData, null, 2)}`;
};

export const isAxiosError = <T = unknown>(
  error: unknown
): error is AxiosError<T> =>
  error instanceof Error && !!(error as AxiosError).isAxiosError;

export interface ParsedAxiosError {
  status: number;
  error: string;
}

export const is404 = (error: unknown): boolean =>
  isAxiosError(error) && error.response?.status === 404;

export const toBody = (args: any): string => {
  return new URLSearchParams(args as Record<string, string>).toString();
};

export const consoleAxiosError = (error: AxiosError): void => {
  logHistory(
    `Axios error Status: ${error.status} Code: ${error.code} Message: ${error.message}`
  );
};
