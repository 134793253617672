import axios from 'axios';

import { showSmileys } from '../../legacy';
import { getApiUrl } from '../../stores/globalState';
import { studentAppModalState } from '../../stores/studentAppModalStore';
import { userState } from '../../stores/userStore';
import { toBody } from '../utils';

import { loginErrorResponseHandler } from './errorResponseHandler';
import { processLogin } from './processLogin';
import { LoginRequest, LoginSuccessResponse } from './types';
import { getParamsFromUrl, markLastLoginTry } from './utils';

export interface LoginOptions {
  onSuccess?: (data: LoginSuccessResponse) => void;
}

/**
 *
 * @param packet, LoginRequest
 * @param showSmiles boolean for whether to rain smileys immediately after login -- sometimes used by loginInternal
 * @param LoginOptions
 * @returns
 */
export const login = async (
  packet: LoginRequest,
  showSmiles = false,
  { onSuccess }: LoginOptions = {}
): Promise<void> => {
  try {
    studentAppModalState().setLoading(true, 'login');
    markLastLoginTry();
    const params = getParamsFromUrl(packet);
    const url = `${getApiUrl()}/Login`;

    // store in case of error and we need to know who it failed on
    userState().updateUserValues({ userName: packet.username });

    const { data } = await axios.post(url, toBody(params));

    if (loginErrorResponseHandler(data)) {
      return;
    }

    // triggers initial render of the app in student.tsx
    onSuccess?.(data);

    await processLogin({ packet, data });
  } catch (err) {
    console.error({ err });
  } finally {
    studentAppModalState().setLoading(false, 'login');

    if (showSmiles) {
      showSmileys();
    }
  }
};
